/*
 * @Description:
 * @Author: shenkaiyao
 * @Date: 2020-09-14 18:36:48
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-25 10:20:56
 */
import api from './index'
import axios from '../axios'
import { TableResponseType } from 'components/table/interface'
import { Id, webAPIRequest, webAPIResponse } from './index.globals'
import useBasicCodes from "../../util/useBasicCodes";

const MODULE = '/basiccode'

type Item = webAPIResponse.BasicCodesItem

export interface BasicCodesType {
  /** 查全部父类型名称 */
  root?: Item[]
  /** 视频存储器 */
  VideoDisk?: Item[]
  /** 传感器类型 */
  SensorType?: Item[]
  /** 旋转方向 */
  VehicleTurnToStatus?: Item[]
  /** 视频通道 */
  VideoChannel?: Item[]
  /** 视频请求类型 */
  VideoDataType?: Item[]
  /** 视频编码类型 */
  VideoEncoderType?: Item[]
  /** 音频编码类型 */
  AudioEncoderType?: Item[]
  /** 存储器类型 */
  StoreType?: Item[]
  /** 码流类型(一共两项，主码流和子码流) */
  StreamType?: Item[]
  /** 码流类型(一共三项，多了一个所有码流) */
  StreamAvType?: Item[]
  /** 附件类型 */
  AttachmentFileType?: Item[]
  /** 处理意见 */
  ProcessSuggest?: Item[]
  /** 终端类型 */
  TerminalType?: Item[]
  /** 终端状态 */
  TerminalState?: Item[]
  /** 终端绑定状态 */
  TerminalBindState?: Item[]
  /** sim卡状态 */
  SimcardStatus?: Item[]
  /** 运营商 */
  SimcardOperator?: Item[]
  /** 车牌颜色 */
  plateColor?: Item[]
  /** 车辆营运状态 */
  RunStatus?: Item[]
  /** 车辆在线状态 */
  OnlineState?: Item[]
  /** 拍照尺寸 */
  Resolution?: Item[]
  /** 录音音频参数 */
  AudioFrequency?: Item[]
  /** 监听类型 */
  ListenTerminal?: Item[]
  /** 拍照类型 */
  FilmMode?: Item[]
  /** 报警来源 */
  AlarmSource?: Item[]
  /** 报警类型 */
  AlarmType?: Item[]
  /** 报警等级 */
  AlarmLevel?: Item[]
  /** 指数类型 */
  IndexType?: Item[]
  /** 视频字幕叠加类型 */
  PlatformVehicleOsd?: Item[]
  /** OSD字母叠加选项 */
  OsdText?: Item[]
  /** 音视频资源类型 */
  VideoResourceType?: Item[]
  /** 车辆GPS状态 */
  VehicleStatus?: Item[]
  /** 视频资源文件上传状态 */
  UploadStatus?: Item[]
  /** 终端命令执行状态 */
  TCommandStatus?: Item[]
  /** 车辆状态 */
  VehicleLocationStatus?: Item[]
  /** 车辆信号状态 */
  VehicleExtraState?: Item[]
  // /** 车辆报警状态 */
  // VehicleAlarmStatus?: Item[]
  /** 区域类型 */
  AreaType?: Item[]
  /** 方向 */
  Direction?: Item[]
  /** 系统相关文本类型 */
  SystemType?: Item[]
  /** 车辆类型 */
  VehicleType?: Item[]
  /** 准驾车型 */
  DrivingType?: Item[]
  /** 统计项 */
  StatisticType?: Item[]
  /** 媒体事件 */
  MediaEvent?: Item[]
  /** 报警督办级别 */
  SupervisionLevel?: Item[]
  /** 车辆行业类型 */
  IndustryType?: Item[]
  /** 保险类型 */
  InsuranceType?: Item[]
  /** 809处理结果 */
  DealStatus809?: Item[]
  /** 用户状态 */
  UserStatus?: Item[]
  /** 保存方式 */
  SaveType?: Item[]
  /** 定时时间单位 */
  IntervalType?: Item[]
  /** 下载条件类型 */
  UploadConditionType?: Item[]

  /** 文本信息 */
  Description?: Item[]
  /** 主动拍照策略 - 高级辅助驾驶系统 */
  PhotoStrategy?: Item[]
  /** 主动拍照策略 - 驾驶员状态监测 */
  DriverPhotoStrategy?: Item[]
  /** 主动拍照策略 - 湘标驾驶员状态监测 */
  DriverPhotoStrategyXB?: Item[]
  /** 拍照分辨率 */
  PhotoResolution?: Item[]
  /** 视频录制分辨率 */
  VideoTranscribeResolution?: Item[]
  /** 事件使能 - 高级辅助驾驶系统 */
  IncidentEnabled?: Item[]
  /** 事件使能 - 驾驶员状态监测 */
  DriverIncidentEnabled?: Item[]
  /** 报警使能 - 高级辅助驾驶系统 - 苏标 */
  AdaAslarmEnable?: Item[]
  /** 报警使能 - 高级辅助驾驶系统 - 湘标 */
  XbAdasAlarmEnabled?: Item[]
  /** 报警使能 - 车辆监测系统 - 湘标 */
  XbVehicleAlarmEnabled?: Item[]
  /** 报警使能 - 驾驶员状态监测 */
  AlarmEnable?: Item[]
  /** 报警使能 - 湘标驾驶员状态监测 */
  AlarmEnableXB?: Item[]
  /** 驾驶员身份识别触发 */
  DriverIdentification?: Item[]

  /** 驾驶员抓拍使能状态 */
  CaptureEnableStatus?: Item[]
  /** 离线人脸比对开关 - 人脸比对 */
  OfflineFaceContrast?: Item[]
  /** 语音播报比对结果使能 - 人脸比对 */
  VoicebroadcastContrast?: Item[]
  /** 设置类型 - 驾驶员信息设置 */
  DriverSettingType?: Item[]

  /** 断油电指令 */
  OilEleType?: Item[]
  /** 权限类型 */
  RightType?: Item[]
  /** 功能类型 */
  ButtonType?: Item[]
  /** 进区域报警类型 */
  InAreaAlarm?: Item[]
  /** 出区域报警类型 */
  OutAreaAlarm?: Item[]
  /** 视频监听自动关闭时间 */
  VideoTimeClose?: Item[]

  /** 终端参数 - 终端电话监听策略 */
  TerminalPhoneMonitoring?: Item[]
  /** 终端参数 - 汇报策略 */
  ReportingStrategy?: Item[]
  /** 终端参数 - 汇报方案 */
  ReportingPlan?: Item[]
  /** 终端参数 - 休眠唤醒模式 */
  SleepWakeMode?: Item[]
  /** 终端参数 - 唤醒条件类型 */
  WakeUpType?: Item[]
  /** 终端参数 - 定时唤醒日 */
  TimedWakeUpDay?: Item[]

  /** 用户设置-第三方地图 */
  MapType?: Item[]
  /** 用户设置-地图车辆标签 */
  VehicleLabelType?: Item[]
  /** 用户设置-实时状态显示类型 */
  VehicleDisplayState?: Item[]
  /** 用户设置-默认视频通道数 */
  VideoShowChannel?: Item[]

  /** 报警设置 - 弹窗颜色 */
  PopupColor?: Item[]
  /** 车标类型 */
  VehicleIconType?: Item[]
  /** 音视频状态通知 */
  NoticVideoStatus?: Item[]
  /** 车辆在线状态报表时长选择 */
  OfflineDuration?: Item[]
  /** 音视频实时传输控制指令 */
  VideoTransmissionControl?: Item[]
  /** 关闭音视频类型 */
  TurnOffVideoType?: Item[]
  /** 门锁控制状态 */
  DoorControlState?: Item[]
  /** 胎压单位 */
  TirePressureUnit?: Item[]

  /** 车辆续时方式 */
  ChangeType?: Item[]

  /** 编码模式 */
  StreamCodingMode?: Item[]
  /** 分辨率 */
  StreamResolution?: Item[]
  /** 视频报警屏蔽项 */
  VideoAlarmShield?: Item[]
  /** 批量指令-多媒体 */
  VideoChannelType?: Item[]
  /** 批量指令-多媒体 */
  ConnectCloudPlatform?: Item[]
  /** 试驾 下拉框 码表 */
  drivedevaluate?: Item[]
  /** 上下线类型 */
  onofflinetype?: Item[]
  /** 批量指令-菜单发送类型 */
  menusendtype?: Item[]
  /** 批量指令-事件发送类型 */
  evensendtype?: Item[]
  /** 批量指令-电话本发送类型 */
  phonebooksendtype?: Item[]
  /** 批量指令-电话本-联系人标志 */
  contactssign?: Item[]
  /**批量指令-提问下发-发送类型 */
  questiondisplaytype?: Item[]

}

export interface AlarmSourceTypeCodePayload {
  alarmSources: string[]
}

/** 报警来源和报警类型 */
export interface BasicCode {
  title: string
  key: string
  children: BasicCode[]
}

export interface DataDictionary {
  /** 父类类型 */
  basicdataTypeKey: string
  /** 父类类型名称 */
  basicdataName: string
  /** 键 */
  key: string
  /** 文本值 */
  text: string
  /** 显示排序 */
  sort: number
  /** en文本值 */
  enText: string
  /** remark */
  remark: string
}

/** 批量码表查询 */
export const getBasicCodes = (payload: webAPIRequest.BasicCodes) =>
  axios.post<BasicCodesType>(`${api}${MODULE}/findBasicCodes`, payload)

/** 报警来源和报警类型查询 */
export const getAlarmSourceTypeCodeList = (payload: AlarmSourceTypeCodePayload) =>
  axios.post<BasicCode[]>(`${api}${MODULE}/getAlarmSourceTypeCodeList`, payload)

/** 右键指令-视频下载专用的报警来源和报警类型查询 */
export const getAlarmTypeForVideoDownload = (payload: AlarmSourceTypeCodePayload) =>
  axios.post<BasicCode[]>(`${api}${MODULE}/getAlarmTypeForVideoDownload`, payload)

/** 报警来源和报警类型查询(返回结果未经过用户设置过滤) */
export const getBasicCodeTree = () => axios.post<BasicCode[]>(`${api}${MODULE}/getBasicCodeTree`)

/** 分页查询数据字典 */
export const getDataDictionaryPage = (payload: webAPIRequest.DataDictionaryPage) =>
  axios.post<TableResponseType<webAPIResponse.DataDictionaryPage>>(
    `${api}${MODULE}/page/list`,
    payload,
  )

/** 查询数据字典详情 */
export const getDataDictionaryDetail = (payload: Id) =>
  axios.post<webAPIResponse.DataDictionaryDetail>(`${api}${MODULE}/detail`, payload)

/** 添加数据字典 */
export const addDataDictionary = (payload: webAPIRequest.AddDataDictionary) =>
  axios.post<boolean>(`${api}${MODULE}/save`, payload)

/** 修改数据字典 */
export const updateDataDictionary = (payload: webAPIRequest.UpdateDataDictionary) =>
  axios.post<boolean>(`${api}${MODULE}/update`, payload)

/** 删除数据字典 */
export const deleteDataDictionary = (payload: Id) =>
  axios.post<boolean>(`${api}${MODULE}/del`, payload)

/** 查询区域树形结构 */
export const queryAreaTree = () => axios.post<webAPIRequest.AreaTree[]>(`${api}${MODULE}/areaTree`)

/** 根据区域code查询区域全称 */
export const queryAreaNameByAreaCode = (payload: { code: string }) =>
  axios.post<string>(`${api}${MODULE}/get/regionName`, payload)
