/*
 * @Description:
 * @Author: 谢永红
 * @Date: 2020-06-08 09:48:36
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-21 17:23:44
 */
// import { lazy } from 'react'
import { RouteProps } from "react-router-dom";
import * as H from "history";
import lazy from "./async";

export interface RoutePropsConfig extends RouteProps {
  name: string;
  intlTitle: string;
  path?: string;
  pagePath?: string;
  /** 是否为标签页， false 单独打开浏览器标签 */
  isTabs?: boolean;
  /** 是否做路由状态保存 */
  isAlive?: boolean;
  /** 路由跳转 */
  redirect?: H.LocationDescriptor;
  icon?: string;
  isIframe?: boolean;
  children?: RoutePropsConfig[];
}

export const RouterMenuIcon = {};

// 首页
const Home = lazy(() => import("view/home"));
// 综合监控
const Monitor = lazy(() => import("view/monitor"));

// 综合监控=新的
const Nmonitor = lazy(() => import("view/monitorN"));
// 新的视频监控
const VideoMonitor = lazy(() => import("view/videoMonitor"));
// 报表中心
const ReportCenter = lazy(() => import("view/reportCenter"));

// 报警次数报表查询
const ReportFrequency = lazy(() => import("view/reportCenter/alarm/frequency"));
// 报警处理报表查询
const ReportProcess = lazy(() => import("view/reportCenter/alarm/process"));
// 报警时长报表查询
const ReportTime = lazy(() => import("view/reportCenter/alarm/time"));

// 车辆在线状态报表查询
const ReportOnlineStatus = lazy(
  () => import("view/reportCenter/train/onlineStatus")
);
// 车辆上线率报表查询
const ReportOnlineRate = lazy(
  () => import("view/reportCenter/train/onlineRate")
);
// 车辆工作率报表查询
const ReportWorkRate = lazy(() => import("view/reportCenter/train/workRate"));
// 车辆日评分报表查询
const ReportDayScore = lazy(() => import("view/reportCenter/train/dayScore"));
// 车辆月评分报表查询
const ReportMonScore = lazy(() => import("view/reportCenter/train/monScore"));

// 企业上线率报表查询
const ReportcOnlineRate = lazy(
  () => import("view/reportCenter/company/onlineRate")
);
// 企业日评分报表查询
const ReportDayGrade = lazy(() => import("view/reportCenter/company/dayGrade"));
// 企业月评分报表查询
const ReportMonthGrade = lazy(
  () => import("view/reportCenter/company/monthGrade")
);

// 司机拔插卡报表查询
const ReportDrawCard = lazy(() => import("view/reportCenter/driver/drawCard"));
// 司机日评分报表查询
const ReportDailyGrade = lazy(
  () => import("view/reportCenter/driver/dailyGrade")
);
// 司机月评分报表查询
const ReportMonGrade = lazy(() => import("view/reportCenter/driver/monGrade"));

// 管理中心
const ManagementCenter = lazy(() => import("view/managementCenter"));
// 商砼
const Concrete = lazy(() => import("view/concrete"));
// 车辆信息
const ManagementVehicle = lazy(
  () => import("view/managementCenter/information/vehicle")
);
// 车组信息
const ManagementCarGroup = lazy(
  () => import("view/managementCenter/information/carGroup")
);
// 终端信息
const ManagementTerminal = lazy(
  () => import("view/managementCenter/information/terminal")
);
// sim卡信息
const ManagementSimCard = lazy(
  () => import("view/managementCenter/information/simCard")
);
// 司机信息
const ManagementDriver = lazy(
  () => import("view/managementCenter/information/driver")
);
// 摄像头信息
const ManagementCamera = lazy(
  () => import("view/managementCenter/information/camera")
);
// 终端日志
const ManagementTerminalLog = lazy(
  () => import("view/managementCenter/log/terminalLog")
);
// 操作日志
const ManagementOperationLog = lazy(
  () => import("view/managementCenter/log/operationLog")
);
// 车辆续时记录
const RenewLog = lazy(() => import("view/managementCenter/renew/renewLog"));
// 车辆续时
const RenewToken = lazy(() => import("view/managementCenter/renew/renewToken"));
// 续时币管理
const VehicleRenew = lazy(
  () => import("view/managementCenter/renew/vehicleRenew")
);

// 查询中心
const QueryCenter = lazy(() => import("view/queryCenter"));
// 车辆报警查询
const QueryVehicle = lazy(() => import("view/queryCenter/alarm/vehicle"));
// 司机报警查询
const QueryDriver = lazy(() => import("view/queryCenter/alarm/driver"));

// 报警证据查询
const QueryEvidence = lazy(() => import("view/queryCenter/alarm/evidence"));
// 历史GPS查询
const QueryGPSHistory = lazy(() => import("view/queryCenter/GPS/history"));
// 定位合格率查询
const QueryAcceptability = lazy(
  () => import("view/queryCenter/GPS/acceptability")
);
// 轨迹完整率查询
const QueryTrackIntegrity = lazy(
  () => import("view/queryCenter/GPS/trackIntegrity")
);
// 传感器查询
const QuerySensor = lazy(() => import("view/queryCenter/sensor/sensor"));
// 正反转传感器查询
const QueryReverseSensor = lazy(
  () => import("view/queryCenter/sensor/reverse")
);
// 载重传感器查询
const QueryLoadSensor = lazy(() => import("view/queryCenter/sensor/load"));
//试驾查询
const QueryTestDrive = lazy(() => import("view/queryCenter/testDrive/query"));
//设备拍照查询
const DevicePhotoQuery = lazy(
  () => import("view/queryCenter/devicePhotoQuery")
);
// 报警地图查询
const AlarmMapQuery = lazy(() => import("view/queryCenter/alarmMapQuery"));

// 车辆上下线记录查询
const VehicleOnoffRecordQuery = lazy(
  () => import("view/queryCenter/vehicleOnoffRecordQuery")
);

// 车辆在线状态查询
const VehicleOnlinequery = lazy(
  () => import("view/queryCenter/vehicleOnlinequery")
);
// 批量指令
const Instructions = lazy(
  () => import("view/managementCenter/rule/instructions")
);
// 电子围栏
const Fence = lazy(() => import("view/managementCenter/rule/fence"));
// 报警设置
const Alarm = lazy(() => import("view/managementCenter/rule/alarm"));
// 用户管理
const ManagementUser = lazy(
  () => import("view/managementCenter/authority/user")
);
// 角色管理
const ManagementRole = lazy(
  () => import("view/managementCenter/authority/role")
);
// 企业管理
const ManagementCompany = lazy(
  () => import("view/managementCenter/authority/company")
);
// 菜单管理
const ManagementMenu = lazy(
  () => import("view/managementCenter/authority/menu")
);
// 数据字典
const DataDictionary = lazy(
  () => import("view/managementCenter/system/dictionary")
);
// 实时评分设置
const RealtimeScore = lazy(
  () => import("view/managementCenter/system/realtimeScore")
);
// 日评分设置
const DataSetWeight = lazy(
  () => import("view/managementCenter/system/setweight")
);
// 企业参数
const EnParameters = lazy(
  () => import("view/managementCenter/system/enParameters")
);
// 平台参数
const Platform = lazy(() => import("view/managementCenter/system/platform"));
// 车标设置
const VehicleIcon = lazy(
  () => import("view/managementCenter/system/vehicleIcon")
);
// H5视频
const Html5Video = lazy(() => import("view/managementCenter/system/htmlVideo"));

// 试驾参数设置
const TestDriveParamet = lazy(
  () => import("view/managementCenter/system/testDriveParamet")
);

// 缓存清理
const CacheClean = lazy(
  () => import("view/managementCenter/system/cacheClean")
);

const devlopmentPage = lazy(() => import("view/developmentPage"));

const defaultMenu: RoutePropsConfig[] = [
  {
    // 首页
    name: "home",
    component: Home,
    icon: "home",
    intlTitle: "tx1801001",
  },
  {
    // 监控
    name: "monitor",
    component: Monitor,
    icon: "monitor",
    intlTitle: "tx1803001",
  },
  {
    // 监控==新的
    name: "Nmonitor",
    component: Nmonitor,
    icon: "monitor",
    intlTitle: "tx1803021",
  },
  {
    // 新的视频监控
    name: "videoMonitor",
    component: VideoMonitor,
    icon: "monitor",
    intlTitle: "tx1803002",
  },
  {
    // 大屏
    name: "largeScreen",
    redirect: "/largeScreen",
    icon: "largeScreen",
    intlTitle: "tx1802001",
    isTabs: true,
  },
  {
    // 商砼大屏
    name: "concreteLargeScreen",
    redirect: "/concreteLargeScreen",
    icon: "largeScreen",
    intlTitle: "tx1802002",
    isTabs: true,
  },
  /** 报表中心 */
  {
    name: "reportCenter",
    component: ReportCenter,
    icon: "reportCenter",
    intlTitle: "tx1804001",
  },
  /** 报表查询 */
  {
    name: "concreteReport",
    component: ReportCenter,
    icon: "reportCenter",
    intlTitle: "tx1804002",
  },
  /** 报警报表查询*/
  {
    name: "frequency",
    component: ReportFrequency,
    intlTitle: "tx1808043",
  },
  {
    name: "process",
    component: ReportProcess,
    intlTitle: "tx1808041",
  },
  {
    name: "time",
    component: ReportTime,
    intlTitle: "tx1808042",
  },
  /** 车辆报表查询*/
  {
    name: "onlineStatus",
    component: ReportOnlineStatus,
    intlTitle: "tx1806071",
  },
  {
    name: "onlineRate",
    component: ReportOnlineRate,
    intlTitle: "tx1806073",
  },
  {
    name: "workRate",
    component: ReportWorkRate,
    intlTitle: "tx1806072",
  },
  {
    name: "dayScore",
    component: ReportDayScore,
    intlTitle: "tx1806074",
  },
  {
    name: "monScore",
    component: ReportMonScore,
    intlTitle: "tx1806075",
  },
  /** 企业报表查询*/
  {
    name: "conlineRate",
    component: ReportcOnlineRate,
    intlTitle: "tx1806073",
  },
  {
    name: "dayGrade",
    component: ReportDayGrade,
    intlTitle: "tx1806074",
  },
  {
    name: "monthGrade",
    component: ReportMonthGrade,
    intlTitle: "tx1806075",
  },
  /** 司机报表查询*/
  {
    name: "drawCard",
    component: ReportDrawCard,
    intlTitle: "tx1808014",
  },
  {
    name: "dailyGrade",
    component: ReportDailyGrade,
    intlTitle: "tx1806074",
  },
  {
    name: "monGrade",
    component: ReportMonGrade,
    intlTitle: "tx1806075",
  },

  /** 管理中心 - start */
  {
    name: "managementCenter",
    component: ManagementCenter,
    icon: "managementCenter",
    intlTitle: "tx1805001",
  },
  {
    name: "vehicle",
    component: ManagementVehicle,
    intlTitle: "tx1805017",
  },
  {
    name: "carGroup",
    component: ManagementCarGroup,
    intlTitle: "tx1805013",
  },
  {
    name: "terminal",
    component: ManagementTerminal,
    intlTitle: "tx1805014",
  },
  {
    name: "simCard",
    component: ManagementSimCard,
    intlTitle: "tx1805015",
  },
  {
    name: "driver",
    component: ManagementDriver,
    intlTitle: "tx1805016",
  },
  {
    name: "camera",
    component: ManagementCamera,
    intlTitle: "tx1805018",
  },

  // 规则管理
  {
    name: "instructions",
    component: Instructions,
    intlTitle: "tx1805022",
  },
  {
    name: "fence",
    component: Fence,
    intlTitle: "tx1805023",
  },
  {
    name: "alarmSetting",
    component: Alarm,
    intlTitle: "tx1805024",
  },

  /** 权限管理 */
  {
    name: "user",
    component: ManagementUser,
    intlTitle: "tx1805031",
  },
  {
    name: "company",
    component: ManagementCompany,
    intlTitle: "tx1805035",
  },
  {
    name: "menu",
    component: ManagementMenu,
    intlTitle: "tx1805036",
  },
  {
    name: "role",
    component: ManagementRole,
    intlTitle: "tx1805034",
  },
  {
    name: "dictionary",
    component: DataDictionary,
    intlTitle: "tx1805052",
  },
  {
    name: "realtimeScore",
    component: RealtimeScore,
    intlTitle: "tx1805056",
  },
  {
    name: "setWeight",
    component: DataSetWeight,
    intlTitle: "tx1805053",
  },
  {
    name: "enParameters",
    component: EnParameters,
    intlTitle: "tx1805057",
  },
  {
    name: "platform",
    component: Platform,
    intlTitle: "tx1805054",
  },
  // 缓存清理设置
  {
    name: "cacheClean",
    component: CacheClean,
    intlTitle: "tx1805071",
  },
  // 试驾参数设置
  {
    name: "testDriveParamet",
    component: TestDriveParamet,
    intlTitle: "tx1805060",
  },
  /** 车标设置 */
  {
    name: "vehicleIcon",
    component: VehicleIcon,
    intlTitle: "tx1805058",
  },
  /** 车标设置 */
  {
    name: "html5Video",
    component: Html5Video,
    intlTitle: "tx1805059",
  },
  /** 日志管理 */
  {
    name: "terminalLog",
    component: ManagementTerminalLog,
    intlTitle: "tx1805043",
  },
  {
    name: "operationLog",
    component: ManagementOperationLog,
    intlTitle: "tx1805042",
  },
  {
    name: "renewLog",
    component: RenewLog,
    intlTitle: "tx1805062",
  },
  {
    name: "renewToken",
    component: RenewToken,
    intlTitle: "tx1805064",
  },
  {
    name: "vehicleRenew",
    component: VehicleRenew,
    intlTitle: "tx1805063",
  },
  /** 管理中心 - end */

  /** 查询中心 */
  {
    name: "queryCenter",
    component: QueryCenter,
    icon: "queryCenter",
    intlTitle: "tx1806001",
  },
  /** 报警查询 */
  {
    name: "queryVehicle",
    component: QueryVehicle,
    intlTitle: "tx18060111",
  },
  {
    name: "queryDriver",
    component: QueryDriver,
    intlTitle: "tx1806012",
  },
  {
    name: "evidence",
    component: QueryEvidence,
    intlTitle: "tx1806013",
  },
  /** 位置查询 */
  {
    name: "history",
    component: QueryGPSHistory,
    intlTitle: "tx1806033",
  },
  /** 定位合格率查询 */
  {
    name: "acceptability",
    component: QueryAcceptability,
    intlTitle: "tx1806037",
  },
  /** 轨迹完整率查询 */
  {
    name: "trackIntegrity",
    component: QueryTrackIntegrity,
    intlTitle: "tx1806032",
  },
  /** 传感器查询 */
  {
    name: "sensor",
    component: QuerySensor,
    intlTitle: "tx1806052",
  },
  {
    name: "reverse",
    component: QueryReverseSensor,
    intlTitle: "tx1806053",
  },
  {
    name: "load",
    component: QueryLoadSensor,
    intlTitle: "tx1806054",
  },

  // 试驾查询
  {
    name: "queryTestDrive",
    component: QueryTestDrive,
    intlTitle: "tx1806121",
  },

  // 设备拍照查询
  {
    name: "devicePhotoQuery",
    component: DevicePhotoQuery,
    intlTitle: "tx607001",
  },

  // 报警地图查询
  {
    name: "alarmMapQuery",
    component: AlarmMapQuery,
    intlTitle: "tx608001",
  },
  // 车辆上下线记录查询
  {
    name: "vehicleOnoffRecordQuery",
    component: VehicleOnoffRecordQuery,
    intlTitle: "tx609001",
  },

  // 车辆在线状态查询
  {
    name: "vehicleOnlinequery",
    component: VehicleOnlinequery,
    intlTitle: "tx6010001",
  },

  /** 商砼车 */
  {
    name: "concrete",
    intlTitle: "tx1807001",
    icon: "concrete",
    component: Concrete,
  },
  {
    name: "stirPage", // 搅拌站设置
    pagePath: process.env.REACT_APP_CONCRETEURL + "/index/message/stir",
    intlTitle: "tx1807022",
    isIframe: true,
  },
  {
    name: "construction", // 客户工地设置
    pagePath: process.env.REACT_APP_CONCRETEURL + "/index/message/construction",
    intlTitle: "tx1807023",
    isIframe: true,
  },
  {
    name: "dispatchCenter", // 调度管理----调度中心
    pagePath: process.env.REACT_APP_CONCRETEURL + "/index/dispatch/center",
    intlTitle: "tx1807012",
    icon: "dispatch",
    isIframe: true,
  },
  {
    name: "carStatus", // 调度管理----车辆状态

    pagePath: process.env.REACT_APP_CONCRETEURL + "/index/dispatch/carStatus",
    intlTitle: "tx1807013",
    isIframe: true,
  },
  {
    name: "dispatchWaybill", // 调度管理----任务运单
    icon: "waybill",
    pagePath: process.env.REACT_APP_CONCRETEURL + "/index/dispatch/waybill",
    intlTitle: "tx1807014",
    isIframe: true,
  },
  {
    name: "statisticsCustomer", // 报表统计----客户发货统计

    pagePath: process.env.REACT_APP_CONCRETEURL + "/index/statistic/customer",
    intlTitle: "tx1807032",
    isIframe: true,
  },
  {
    name: "statisticsCar", // 报表统计----车辆送货统计

    pagePath: process.env.REACT_APP_CONCRETEURL + "/index/statistic/car",
    intlTitle: "tx1807033",
    isIframe: true,
  },
  {
    name: "statisticsAlarm", // 报表统计----报警统计

    pagePath: process.env.REACT_APP_CONCRETEURL + "/index/statistic/alarm",
    intlTitle: "tx1807035",
    isIframe: true,
  },
  {
    name: "statisticsTurn", // 报表统计----转向统计
    pagePath: process.env.REACT_APP_CONCRETEURL + "/index/statistic/turn",
    intlTitle: "tx1807034",
    isIframe: true,
  },
];

export default defaultMenu;
