/*
 * @Description:
 * @Author: shenkaiyao
 * @Date: 2020-08-03 16:48:19
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-08-20 17:21:03
 */
export default {
  confirm: '确定',
  add: '新增',
  delete: '删除',
  update: '修改',
  cancel: '取消',
  operate: '操作',
  query: '查询',
  reset: '重置',
  export: '导出',
  columnSetting: '列设置',
  view: '查看',
  send: '发送',
  remark: '备注',
  serialNumber: '序号',
  startTime: '开始时间',
  endTime: '结束时间',
  // 选择
  'comm101': 'Please select {value}',
  // 输入
  'comm201': 'Please enter{value}',
  'comm202': 'Please enter{value}{value2}',
  'comm203': 'Please enter{value}{value2}{value3}',
  'comm211': 'Minimum input{value}character',
  'comm212': 'Maximum input{value}character',
  // 公用
  'comm901': 'name',
  'comm902': '/',
  'comm903': 'or',
  'comm904': 'whole',
  'comm905': 'onLine',
  'comm906': 'offLine',
  'comm907': '行驶',
  'comm908': '停车',
  'comm909': '报警',
  // 公用-操作
  tx000001: "OK",
  tx000002: "Cancel",
  tx000003: "Add",
  tx000004: "Delete",
  tx000005: "Modify",
  tx000006: "Query",
  tx000007: "Operations",
  tx000008: "Reset",
  tx000009: "Export",
  tx000010: "View",
  tx000011: "Cols Settings",
  tx000012: "Send",
  tx000013: "Download",
  tx000014: "Location Tracking",
  tx000015: "Audio Monitoring",
  tx000016: "Voice Dialogue",
  tx000017: "Text Command",
  tx000018: "Minimize",
  tx000019: "Window",
  tx000020: "Maximize",
  tx000021: "Batch Modify",
  tx000022: "Import",
  tx000023: "Template Download",
  tx000024: "Operation Successful",
  tx000025: "Select All",
  tx000026: "Batch File Download",
  tx000027: "Change Password",
  tx000028: "Logout",
  tx000029: "Authorize",
  tx000030: "Withdraw",
  tx000031: "View Results",
  tx000032: "Disable",
  tx000033: "Unlock",
  tx000034: "Enable",
  tx000035: "Full Screen",
  tx000036: "Exit",
  tx000037: "Get Params",
  tx000038: "Set Params",
  tx000039: "Select All",
  tx000040: "More",
  tx000041: "Clear All Polls",
  tx000042: "Clean up",
  tx000043: "Withdraw Platform",
  tx000044: "Withdraw Terminal",
  tx000045: "Reissue Terminal",
  tx000046: "Show Sub",
  tx000047: "Share",
  tx000048: "Save",
  tx000049: "Query Params",
  tx000050: "Get infos by CMD",
  tx000051: "User Center",
  tx000052: "Organizations",
  tx000053: "Close",
  tx000054: "Roll Call",
  tx000055: "Upload",
  tx000056: "Refresh",
  tx000057: "Params",
  tx000058: "Display Invalid",
  tx000059: "Continue",
  tx000060: "Import Successful",
  tx000061: "Withdraw CMD",
  tx000062: "Batch Del",
  tx000063: "Commamd(X)",
  tx000064: "Commamd(S)",
  tx000065: "Edit",
  tx000066: "Clear",
  tx000067: "Voice intercom",

  // 公用-显示
  tx000100: "{value}{value2}",
  tx000101: "No.",
  tx000102: "Time",
  tx000103: "Start Time",
  tx000104: "End Time",
  tx000105: "Remark",
  tx000106: "Address",
  tx000107: "No Data",
  tx000108: "Name",
  tx000109: "All",
  tx000110: "Location",
  tx000111: "Basic Info",
  tx000112: "Real-time Location",
  tx000113: "Location Playback",
  tx000114: "Live Video",
  tx000115: "Video Playback",
  tx000312: "Audio Playback",
  tx000311: "Test Drive Process",
  tx000313: "Test Drive Info",
  tx000116: "Real-time Location",
  tx000117: "Automatically Refresh",
  tx000118: "Select All",
  tx000119: "Classes",
  tx000120: "Fleet/Vehicle AlarmSum TOP5",
  tx000121: "Security Big Data Dashboard",
  tx000122: "Day",
  tx000123: "Yesterday",
  tx000124: "Today",
  tx000125: "Last Three Days",
  tx000126: "Date Span",
  tx000127: "Total Duration",
  tx000128: "Location Exception",
  tx000129: "Loaction Completeness Rate",
  tx000130: "Loaction Segmentation",
  tx000131: "Loaction Mileage",
  tx000132: "Start",
  tx000133: "End",
  tx000134: "Duration",
  tx000135: "Check On-post",
  tx000136: "Dangerous Vehicle Reminder",
  tx000137: "The end time cannot be less than the start time",
  tx000138: "The date range cannot exceed 3 days",
  tx000139: "Creation Date",
  tx000140: "Concrete Big Data",
  tx000141: "Real-time Vehicle Data",
  tx000142: "Real-time Volume Data",
  tx000143: "Real-time Supply Data",
  tx000144: "Real-time Waybill Data",
  tx000145: "Real-time Alarm Vehicle Information",
  tx000146: "Pre-arranged vehicles",
  tx000147: "Rest vehicles",
  tx000148: "Dispatched Vehicles",
  tx000149: "Repairing Vehicles",
  tx000150: "Estimated Total Volume",
  tx000151: "Completed Total Volume",
  tx000152: "Transporting Total Volume",
  tx000153: "Number of Sites",
  tx000154: "Number of Sites in Supply",
  tx000155: "Number of Transport",
  tx000156: "Waybill No.",
  tx000157: "Waybill Volume",
  tx000158: "Sites in Supply",
  tx000159: "Task Waybill Data",
  tx000160: "Online Rate",
  tx000161: "Risk Rate",
  tx000162: "Time Axis",
  tx000163: "Video Files",
  tx000164: "Send Scheduling Info",
  tx000165: "Send Results",
  tx000166: "Query Time",
  tx000167: "Task Execution Conditions",
  tx000168: "Send Status",
  tx000169: "Send time",
  tx000170: "Execution Status",
  tx000171: "Execution Time",
  tx000172: "Level",
  tx000173: "Super Danger",
  tx000174: "High Danger",
  tx000175: "Danger",
  tx000176: "Relative Danger",
  tx000177: "Normal",
  tx000178: "Type Name",
  tx000179: "EN",
  tx000180: "Modification Date",
  tx000181: "Modified By",
  tx000182: "Set-up Fences",
  tx000183: "Fence Setting",
  tx000184: "Rule Type",
  tx000185: "Yes",
  tx000186: "No",
  tx000187: "Fence Details",
  tx000188: "Time Unit",
  tx000189: "Site",
  tx000190: "Transportation Volume",
  tx000191: "ZH-CN Display",
  tx000192: "EN-US Display",
  tx000193: "Sorting",
  tx000194: "Please draw the fence",
  tx000195: "Initializing...",
  tx000196: "Uploading...",
  tx000197: "Upload Successful",
  tx000198: "Loaction Segmentation",
  tx000199: "Discontinuous Loaction",
  tx0001100: "Loading...",

  // 公用-提示
  tx000201: "Please Select {value}",
  tx000202: "Please Input {value}",
  tx000203: "Please Input {value}{value2}",
  tx000204: "Please Input {value}/{value2}",
  tx000205: "Input at least {value} Characters",
  tx000206: "Input up to {value} Characters",
  tx000207: "Please Select {value}/{value2}",
  tx000208: "Please Select {value} {value2}",
  tx000209: "{value} Select",
  tx000210: "Up to {value} can be checked",
  tx000211: "{value} cannot be empty",
  tx000212: "{value} in total",
  tx000213: "Selected {value} {value2} in total",
  tx000214: "Are you sure about {value}?",
  tx000215: "Click to upload {value}",
  tx000216: "Only files in JPG or PNG format can be uploaded",
  tx000217: "Search Command",
  tx000218: "This vehicle has no video channel",
  tx000219: "The time range cannot exceed 24 hours",
  tx000220: "Input the address",
  tx000221: "No location found",
  tx000222: "Cannot be greater than {value}",
  tx000223: "Cannot be less than {value}",
  tx000224: "Search Menu",
  tx000225: "Please contact the administrator to configure the permission menu",
  tx000226: "Please first draw the road section on the left map",
  tx000227: "Waiting for sending",
  tx000228: "Please add at least one rule",
  tx000229: "There is overlap in the range of alarm numbers",
  tx000230: "There are duplicate alarm numbers",
  tx000231: "Alarm type duplicate",
  tx000232: "The minimum number of alarms must not exceed the maximum number of alarms",
  tx000233: "The minimum speed value must not exceed the maximum speed value",
  tx000234: "The minimum score must not exceed the maximum score",
  tx000235: "There is overlap in the speed range",
  tx000236: "There is overlap in the score range",
  tx000237: "Please input a value between 1 and 60",
  tx000238: "Please input a value between 60 and 200",
  tx000239: "Please input {value} - {value1}",
  tx000240: "The decimal part is {value} - {value1} places",
  tx000241: "Only XLS format files can be uploaded",
  tx000242: "Please input a value between 1 and 100",
  tx000243: "Please input a value between 0 and 100",
  tx000244: "Please input ",

  // 车辆
  tx010000: "Quick Add",
  tx010001: "Plate No.",
  tx010002: "Fleet",
  tx010003: "Color",
  tx010004: "Speed",
  tx010005: "Oil Quantity",
  tx010006: "Direction",
  tx010007: "Longitude",
  tx010008: "Latitude",
  tx010009: "Original Longitude",
  tx010010: "Original Latitude",
  tx010011: "Plate Color",
  tx010012: "Front Vehicle Speed",
  tx010013: "Front Vehicle/Pedestrian Distance",
  tx010014: "Vehicle Status",
  tx010015: "Information",
  tx010016: "Vehicle",
  tx010017: "Vehicle Quantity",
  tx010018: "Vehicle Quantity",
  tx010019: "Total Mileage",
  tx010020: "Vehicle Rankings TOP10",
  tx010021: "Vehicle Info",
  tx010022: "Minimum Speed",
  tx010023: "vehicle No.",
  tx010024: "Self No.",
  tx010025: "Vehicle Residence",
  tx010026: "Industry Type",
  tx010027: "Vehicle Type",
  tx010028: "Licence No.",
  tx010029: "Client Name",
  tx010030: "License ID",
  tx010031: "Business Area",
  tx010032: "Validation",
  tx010033: "Licence",
  tx010034: "Drive License Info",
  tx010035: "Proximity",
  tx010036: "VIN",
  tx010037: "Usage",
  tx010038: "Brand Model",
  tx010039: "Register Date",
  tx010040: "Validation",
  tx010041: "Drive Licence",
  tx010042: "Insurance No.",
  tx010043: "Insurance type",
  tx010044: "Insurance Company",
  tx010045: "Insurance Amount",
  tx010046: "Discount Rate",
  tx010047: "Real Cost",
  tx010048: "Agent",
  tx010049: "Agent Phone",
  tx010050: "Maintain Time",
  tx010051: "Send Maintaince Time",
  tx010052: "Repair Time",
  tx010053: "Repair Cost",
  tx010054: "Repair Content",
  tx010055: "Terminal Unique ID",
  tx010056: "Terminal Model",
  tx010057: "Terminal ID",
  tx010058: "Platform Unique Encode",
  tx010059: "Vehicle Cornering",
  tx010060: "Usage Type",
  tx010061: "Working Status",
  tx010062: "Location Data",
  tx010063: "Realtime Speed",

  // 车辆 - 状态
  tx010101: "Status",
  tx010102: "Online",
  tx010103: "Offline",
  tx010104: "Moving",
  tx010105: "Parking",
  tx010106: "Alarm",
  tx010107: "Online Rate",
  tx010108: "Offline Rate",
  tx010109: "Running Status",
  tx010110: "Online Veh QTY",
  tx010111: "Offline Veh QTY",
  tx010112: "Veh QTY",

  // 报警
  tx020000: "Warning Files",
  tx020001: "Source",
  tx020002: "Warning Type",
  tx020003: "Warning Time",
  tx020004: "Alarm Address",
  tx020005: "Alarm Level",
  tx020006: "Attachment Quantity",
  tx020007: "Warning mark",
  tx020008: "Route Deviation Type",
  tx020009: "Road Sign Recognition Type",
  tx020010: "Road Sign Recognition Data",
  tx020011: "Tire Pressure Alarm Content",
  tx020012: "Alarm Solve",
  tx020013: "Warning Video",
  tx020014: "Warning Picture",
  tx020015: "Send Text",
  tx020016: "Send Content",
  tx020017: "Text",
  tx020018: "Common Text",
  tx020019: "Add to Common",
  tx020020: "Send Command",
  tx020021: "Photo Shoot",
  tx020022: "Photo Shoot Info",
  tx020023: "Photo Shoot Type",
  tx020024: "Camera",
  tx020025: "Alarm Details",
  tx020026: "Warning Files",
  tx020027: "Alarm",
  tx020028: "Warning times",
  tx020029: "24-hour alarm Scatter",
  tx020030: "Real time alarm vehicle information",
  tx020031: "Average Alarm",
  tx020032: "Alarms for 100KM",
  tx020033: "Alarm Trends",
  tx020034: "Major Alarm Stat",
  tx020035: "Alarm Type Scatter",
  tx020036: "Warning End Time",
  tx020037: "Result",
  tx020038: "Realtime Alarm",
  tx020039: "Abnormal drive behavior Alarm",
  tx020040: "Licence Info",
  tx020041: "Insurance Info",
  tx020042: "Repair Info",
  tx020043: "Upper Register Info",
  tx020044: "Alarm Remark",
  tx020045: "Speed Limit(km/h)",
  tx020046: "Overtime Driving Duration(Min)",
  tx020047: "Overtime Driving Class",
  tx020048: "Compare Similarity",
  tx020049: "Comparison Type",
  tx020050: "Photo Quantity",
  tx020051: "Shooting Interval(Sec)",
  tx020052: "Save Types",
  tx020053: "Photo Size",
  tx020054: "Quality(1-10)",
  tx020055: "Light(0~255)",
  tx020056: "Contrast(0~127)",
  tx020057: "Saturation(0~127)",
  tx020058: "Tint(0~255)",
  tx020059: "Shooting Interval",
  tx020060: "Advanced Settings",
  tx020061: "Alarm info",

  // 处理
  tx030001: "Action",
  tx030002: "Execute Status",
  tx030003: "Execute Advice",
  tx030004: "Solved",
  tx030005: "Treatment Pending",
  tx030006: "No treatment",
  tx030007: "Successfully Processed",
  tx030008: "Failed Processed",
  tx030009: "Solve",
  tx030010: "Execute Method",
  tx030011: "Execute Time",
  tx030012: "Execute Person",

  // 督办
  tx040001: "Deadline",
  tx040002: "Level",
  tx040003: "Superviser",
  tx040004: "Supervise",
  tx040005: "Upper A/C msg.",
  tx040006: "Supervise ID",
  tx040007: "Superviser",
  tx040008: "Supervise Info",

  // 联系人
  tx050001: "E-mail",
  tx050002: "Phone",
  tx050003: "Contact",
  tx050004: "Enterprise No.",

  // 设备
  tx060001: "Sim Card No.",
  tx060002: "Online",
  tx060003: "Pulse Speed",
  tx060004: "Gps Locate Time",
  tx060005: "Mileage",
  tx060006: "Daily Mileage",
  tx060007: "Total Mileage",
  tx060008: "Located",
  tx060009: "Altitude",
  tx060010: "Signal Strength",
  tx060011: "Multi-media Type",
  tx060012: "Upload Time",
  tx060013: "Delay(s)",
  tx060014: "Used(s)",
  tx060015: "Recordor Speed",
  tx060016: "Validation",
  tx060017: "Terminal No.",
  tx060018: "Terminal Type",
  tx060019: "Sim Card No.",
  tx060020: "Terminal Status",
  tx060021: "Bond Sim Card",
  tx060022: "All Channals",
  tx060023: "All Storage",
  tx060024: "Main Storage",
  tx060025: "Backup Storage",
  tx060026: "Bond Status",
  tx060027: "Terminal",
  tx060028: "Device ID",
  tx060029: "Installation Time",
  tx060030: "Installer",
  tx060031: "Hardware Model",
  tx060032: "Software Version",
  tx060033: "Sim Card ",
  tx060034: "Status",
  tx060035: "TELCO",
  tx060036: "Location Addr",
  tx060037: "Bond Terminal",
  tx060038: "Terminal Info",
  tx060039: "Terminal ID",
  tx060040: "Terminal model",
  tx060041: "GNSS Module Property",
  tx060042: "Cellular Module Property",

  // 视频
  tx070001: "Multi-media Type",
  tx070002: "Rate Type",
  tx070003: "Storage Type",
  tx070004: "Size(KB)",
  tx070005: "Channel",
  tx070006: "Channel No.",
  tx070007: "Event Type",
  tx070008: "Play",
  tx070009: "Pause",
  tx070010: "Stop",
  tx070011: "Progress",
  tx070012: "Source",
  tx070013: "Audio",
  tx070014: "Video",
  tx070015: "AV",
  tx070016: "Video / Audio",
  tx070017: "Mainstream vVideo Rate",
  tx070018: "Substream Video Rate",
  tx070019: "All Play",
  tx070020: "All Pause",
  tx070021: "Channel",
  tx070022: "Immediate Photo",
  tx070023: "Timing Photo",
  tx070024: "CH1",
  tx070025: "CH2",
  tx070026: "CH3",
  tx070027: "CH4",
  tx070028: "CH5",
  tx070029: "Fixed Time Photo",
  tx070030: "Upload Photo",
  tx070031: "Fixed photo shoot param settings",
  tx070032: "Audio/Video Type",

  // axios
  tx08_0: "Waiting for server response",
  tx08_1: "Request timeout",
  tx08_2: "The maximum upload file size is 10M",
  tx080001: "Upload failed",
  tx08400: "Bad request",
  tx08401: "Unauthorized, please log in again",
  tx08403: "Prohibit access",
  tx08404: "No resources found",
  tx08405: "The current request type is not supported",
  tx08408: "Request timeout",
  tx08429: "Request exceeds the limit of times",
  tx08500: "Internal service error",
  tx08502: "Bad Gateway",
  tx08503: "The service is unavailable",
  tx08504: "Gateway timeout",
  tx081001: "The request must include at least one valid file",
  tx081024: "The server is busy, please try again later",
  tx083004: "Vehicle not online",
  tx084004: "Other users are replaying the video, please try again later",
  tx0810104: "The new password cannot be the same as the old password",
  tx0810105: "The new password does not match the confirmed password",
  tx0810107: "Old password error",
  tx0810108: "Organization name already exists",
  tx0810109: "Existence of sub organizations",
  tx0810010: "Incorrect username or password",
  tx0810011: "Your account or password has been entered incorrectly more than 5 times. After entering incorrectly 10 times, your account will be locked and unable to log in",
  tx0810012: "Your account has been locked and cannot be logged in. Please contact the administrator to unlock it",
  tx0810101: "User account already exists",
  tx0810102: "Inconsistent account and password",
  tx0810110: "User already exists",
  tx0810111: "Fleet already exists",
  tx0810112: "Driver already exists",
  tx0810113: "SIM Crad already exists",
  tx0810114: "Terminal already exists",
  tx0810115: "Role already exists",

  tx0820001: "No code table data obtained",
  tx0820002: "Common text already exists, add failed",
  tx0820003: "Common text is empty, add failed",
  tx0820004: "Common text addition failed",
  tx0820005: "Common text deletion failed",
  tx0820006: "Alarm type duplicate",
  tx0820007: "The key under this type is duplicated",
  tx0823001: "Vehicle Icon data does not exist",
  tx0823002: "The vehicle status icon already exists and cannot be added again",

  tx0830001: "Vehicle does not exist",
  tx0830003: "Unbind failed",
  tx0830004: "SIM card no. already exists",
  tx0830005: "SIM card already bound",
  tx0830006: "Terminal already exists",
  tx0830007: "Terminal already bound",
  tx0830008: "Self No. already exists",
  tx0830009: "Sim Card No. already exists",
  tx0830010: "plate No. already exists",
  tx0830011: "Vehicle addition failed",
  tx0830012: "Vehicle modification failed",
  tx0830013: "Vehicle deletion failed",
  tx0830014: "Binding failed",
  tx0830015: "Terminal does not exist",
  tx0830016: "Driver addition failed",
  tx0830017: "Driver modification failed",
  tx0830018: "Driver bound to vehicle, deletion failed",

  tx0831001: "Add failed",
  tx0831002: "Modification failed",
  tx0831003: "Unable to delete, there are vehicles under the fleet",
  tx0831004: "SIM card No. already exists",
  tx0831005: "SIM card already bound",
  tx0831006: "Terminal already exists",

  tx0860001: "No data found",
  tx0860002: "Vehicle location acquisition failed",
  tx0860003: "Export missing response object",
  tx0860004: "Export data as null",
  tx0860005: "Missing title bar when exporting to Excel",
  tx0860006: "The time range cannot exceed 7 days",
  tx0860007: "The time range cannot exceed 2 months",
  tx0860008: "Please select a time range",
  tx0860009: "Please select the vehicle",
  tx0860010: "Please check if the data is duplicated",
  tx0860011: "There are vehicles under the electronic fence that cannot be deleted",
  tx0865009: "Unable to find this vehicle",

  tx0882003: "Request missing parameters",
  tx0883004: "Vehicle not online",
  tx0883006: "No data foun",
  tx0884003: "Switching failed, multiple users are watching",
  "tx08-1": "System busy~Please try again later~",
  "tx08-2": "System maintenance in progress~Please try again later~",
  "tx08-3": "Parameter type parsing exception",
  "tx08-4": "An exception occurred while running SQL",
  "tx08-5": "null pointer exception",
  "tx08-6": "Invalid parameter exception",
  "tx08-7": "Request type exception",
  "tx08-8": "Error loading resources",
  "tx08-9": "Unified validation parameter exception",
  "tx08-10": "Unified validation parameter exception",
  "tx08-11": "Mapper class conversion exception",
  "tx08-12": "Verification code verification failed",
  "tx08-16": "file does not exist",

  // 登录login
  tx090001: "Login",
  tx090002: "Logging in...",
  tx090003: "Login Succeeded",
  tx090004: "APP Download QR Code",
  tx090005: "User Account",
  tx090006: "Password",
  tx090007: "Matching Successful",
  tx090008: "Matching Failed",
  tx090009: "Caps locked on",
  tx090010: "Mini program download QR code",

  // 平台信息
  tx100001: "Active Safety Monitor Platform",
  tx100002: "TianXun Active Safety Monitor Platform",
  tx100003: "Tianconcrete Intelligent Scheduling System",
  tx100004: "Tianconcrete Intelligent Scheduling System",
  tx100005: "Concrete Intelligent scheduling system",

  // 司机
  tx110001: "Driver Name",
  tx110002: "Fatigue Level",
  tx110003: "Driver",
  tx110004: "Driver",
  tx110005: "Resident ID",
  tx110006: "Qualification Certificate",
  tx110007: "Issue Institution",
  tx110008: "Vehicle Guard",
  tx110009: "Vehicle Model",
  tx110010: "Birthday",
  tx110011: "IC Card No.",
  tx110012: "Annual inspection date",
  tx110013: "Issue date",
  tx110014: "Validation",
  tx110015: "Supervision Institution",
  tx110016: "Supervision Phone",
  tx110017: "Drive licence",
  tx110018: "Front of ID card",
  tx110019: "Back of ID card",
  tx110020: "Driver Licence Info",
  tx110021: "Bond Driver",
  tx110022: "Chief Driver",
  tx110023: "Driver Photo",
  tx110024: "Age",
  tx110025: "Gender",

  // 监听
  tx120001: "Listen-in",
  tx120002: "Listen-in Info",
  tx120003: "Listen-in Type",
  tx120004: "Listen-in Call",

  // 企业
  tx130000: "Orgs",
  tx130001: "Organization",
  tx130002: "Fleet",
  tx130003: "Fleet Rankings TOP10",
  tx130004: "Organization Name",
  tx130005: "Superior Organization",
  tx130006: "Organizational Data Authorization",
  tx130007: "Unauthorized",
  tx130008: "Authorized",
  tx130009: "Revoke Authorization",
  tx130101: "Superior Fleet",

  // 商砼
  tx140001: "Concrete",
  // 任务运单
  tx140101: "Delivery Bill No.",
  tx140102: "This Volume",
  tx140103: "Delivery Status",
  tx140104: "Supplying Site Address",

  // 量词
  tx150001: "Veh",
  tx150002: "times",
  tx150003: "KM",
  tx150004: "H",
  tx150005: "S",
  tx150006: "M",
  tx150007: "Page",
  tx150008: "degrees",
  tx150009: "Min",

  // 表单验证
  tx1600001: "Digit",
  tx1600002: "Chinese Char",
  tx1600003: "Char",
  tx1600004: "Please enter the correct phone number",
  tx1600005: "Please enter a number with a maximum of 1 decimal place",
  tx1600006: "Please enter the correct ID number number",
  tx1600007: "Please enter a valid number",
  tx1600050: "Modifying the organization of the fleet will have an impact on the ownership of vehicles under the fleet. Please synchronize the modification of the vehicle's organization and fleet",
  tx1600051: "The selection time cannot exceed 30 minutes",
  tx1600052: "The limit for querying a single time range is two months, and the limit for querying multiple time ranges is seven days",

  // 首页
  tx1700001: "Operation Index Warning",
  tx1700002: "Stability Alarm",
  tx1700003: "Dangerous Drive Alarm",
  tx1700004: "Driver Fatigue Warning",
  tx1700005: "Non-professional Drive Alarm",
  tx1700006: "Forward Collision Warning",
  tx1700007: "Smoking Warning",
  tx1700008: "Lane Departure Warning",
  tx1700009: "Over-speed Alarm(Terminal)",
  tx1700010: "Calling Warning",
  tx1700011: "Driver Abnormal Warning",
  tx1700012: "Pedestrian Collision Warning",

  // 菜单
  tx1801001: "Home",
  tx1802001: "Dashboard",
  tx1802002: "Concrete Dashboard",
  tx1803001: "Monitor",
  tx1803021: "Monitor Center",
  tx1803002: "Video Monitor",
  tx1804001: "Report",
  tx1804002: "Concrete",
  tx1804003: "Order Waybill",
  // 管理中心
  tx1805001: "Management",
  // 管理中心 - 信息管理
  tx1805011: "Vehicle Center",
  tx1805012: "Organization Info",
  tx1805013: "Fleets",
  tx1805014: "Terminals",
  tx1805015: "Sim Cards",
  tx1805016: "Drivers",
  tx1805017: "Vehicles",
  tx1805018: "Cameras",
  tx18050180: "Camera",
  tx18050181: "Camera Name",
  tx18050182: "CameraUID",
  tx18050183: "User",
  tx18050184: "Password",
  tx18050185: "Creation time",
  // 管理中心 - 规则管理
  tx1805021: "Rule Center",
  tx1805022: "Batch Commands",
  tx1805023: "Electronic Fence",
  tx1805024: "Alarm Settings",
  // 管理中心 - 用户管理
  tx1805031: "Users",
  tx1805032: "User Info",
  tx1805033: "Organization Center",
  tx1805034: "Roles",
  tx1805035: "Organizations",
  tx1805036: "Menus",
  // 管理中心 - 日志管理
  tx1805041: "Log Center",
  tx1805042: "Operation Logs",
  tx1805043: "Command Logs",
  // 管理中心 - 系统管理
  tx1805051: "SYS",
  tx1805052: "Data Dictionary",
  tx1805053: "Daily score Params",
  tx1805054: "Platform Params",
  tx1805055: "video Server Params",
  tx1805056: "Real score Params",
  tx1805057: "Org params",
  tx1805058: "Vehicle Icon Setting",
  tx1805059: "HTML5 Video",
  tx1805060: "Test Drive params",
  tx1805071: "Cache Cleaning",
  // 管理中心 - 续时管理
  tx1805061: "Continuation",
  tx1805062: "Continuation Record",
  tx1805063: "Continuation",
  tx1805064: "CONT Coin",
  tx1805065: "CONT Coin Recharge",
  tx1805066: "Recharge Amount",
  tx1805067: "Balance",
  tx1805068: "Continuation Duration",
  tx1805069: "Continuation Method",
  tx18050610: "Cost",
  tx18050611: "Renewal",
  tx18050612: "Batch Renewal",
  tx18050613: "Expiration Status",
  tx18050614: "Remaining / Expired Days",
  tx18050615: "Expire Date",
  tx18050616: "Installed Date",
  tx18050617: "Organization",
  tx18050618: "Balance",
  tx18050619: "Recharge",
  tx18050620: "Expiration Vehicle list",
  tx18050621: "Select Continuation Duration ",
  tx18050622: "Expired",
  tx18050623: "Past 3 days",
  tx18050624: "Past 7 days",
  tx18050625: "Past 30 days",
  tx18050626: "Vehicles Amount",
  tx18050627: "Payment Method",
  tx18050628: "Total Cost",
  tx18050629: "Custom Months",
  tx18050630: "Out of balance",
  tx18050631: "Cannot recharge self-organization",

  // 查询中心
  tx1806001: "Query Center",
  // 查询中心 - 报警查询
  tx1806011: "Query Alarms",
  tx1806012: "Driver Alarms",
  tx1806013: "Alarm Attachments",
  tx1806014: "Bulk Solve Alarms",
  tx1806015: "Alarm Solve Record ",
  tx1806016: "Real Alarm Solve",
  tx1806017: "Alarm Map",
  tx1806018: "Alarm Count",
  tx1806019: "Alarm Duration",
  tx18060110: "Driving Alarm",
  tx18060111: "Vehicle Alarm",

  // 查询中心 - 行车查询
  tx1806021: "Query Driving",
  tx1806022: "Online/Offline Status",
  tx1806023: "Online/Offline Records",
  tx1806024: "Entry/Exit Fence Records",
  tx1806025: "Card Insert/Remove Records",
  tx1806026: "Vehicle Working Hours",
  // 查询中心 - 位置查询
  tx1806031: "Query Location",
  tx1806032: "Track Completenes",
  tx1806033: "Historical Location",
  tx1806034: "Gps Ledger",
  tx1806035: "Mileage",
  tx1806036: "Mileage Difference",
  tx1806037: "Location qualification rate",

  // 查询中心 - 多媒体查询
  tx1806041: "Query Multimedia",
  tx1806042: "Equipment license plate",
  tx1806043: "Video Play Records",
  tx1806044: "Server Video",
  tx1806045: "Video Upload Records",
  tx1806046: "AV Data Traffic Stat",
  // 查询中心 - 传感器查询
  tx1806051: "Query Sensor",
  tx1806052: "Sensor Upload Data",
  tx1806053: "Forward/Reverse Sensor Upload Data",
  tx1806054: "Load Sensor Upload Data",
  // 查询中心 - 传感器查询
  tx1806061: "Sensor Data",
  // 查询中心 - 车辆报表
  tx1806071: "Query Online Status",
  tx1806072: "Query Efficiency",
  tx1806073: "Query Online Rate",
  tx1806074: "Query Daily Score",
  tx1806075: "Query Month Scor",
  tx1806076: "Online Status",
  tx1806077: "Last Online Time",
  tx1806078: "Last Online Location",
  tx1806079: "Statistic Time",
  tx1806080: "Online Time",
  tx1806081: "Offline Time",
  tx1806082: "Working Time（h）",
  tx1806083: "Statistic Time",
  tx1806084: "Efficiency（%）",
  tx1806085: "Alarm Scatter",
  tx1806086: "Score",
  tx1806087: "Monitoring Object",
  tx1806088: "Running Type",
  tx1806089: "Mileage",
  tx1806090: "Trips Number",
  tx1806091: "Daily Average Duration",
  tx1806092: "Average Length",
  tx1806093: "Alarm Quantity",
  tx1806094: "Overall Score",
  tx1806095: "Alarm Scatter(100km)",
  tx1806096: "Alarm Scatter(H)",

  // 查询中心 - 位置查询 - 定位合格率查询
  tx1806101: "Statistic Date",
  tx1806102: "Total Number Locations",
  tx1806103: "Number Bad Locations",
  tx1806104: "Qualification Rate",

  // 查询中心 - 位置查询 - 轨迹完整率查询
  tx1806111: "Continuous Mileage(km)",
  tx1806112: "Total Mileage(km)",
  tx1806113: "Trip Complete Rate(%)",

  //查询中心-试驾查询
  tx1806121: "Query Test Drive ",

  // 商砼车
  tx1807001: "Concrete Truck",
  // 商砼车 - 调度管理
  tx1807011: "Dispatch Management",
  tx1807012: "Dispatching",
  tx1807013: "Query Vehicle Status",
  tx1807014: "Orders",
  // 商砼车 - 信息管理
  tx1807021: "concrete Center",
  tx1807022: "concrete Sites",
  tx1807023: "Construction Sites",
  // 商砼车 - 报表管理
  tx1807031: "Report Center",
  tx1807032: "Customer shipment",
  tx1807033: "Vehicle Delivery",
  tx1807034: "Vehicle Steering",
  tx1807035: "Vehicle Alarm",
  // 报表中心
  tx1808001: "报警统计",
  // 报表中心-司机报表
  tx1808011: "司机报表",
  tx1808012: "企业司机月度报表",
  tx1808013: "司机安全报表",
  tx1808014: "拔插卡查询",
  tx1808015: "操作类型",
  tx1808016: "读卡结果",
  tx1808017: "驾驶时间(小时)",
  tx1808018: "昨天分数",
  tx1808019: "今天分数",
  // 报表中心-企业报表
  tx1808021: "报警统计",
  tx1808022: "企业安全报表",
  tx1808023: "企业上线率报表",
  tx1808024: "企业报表",
  tx1808025: "在线车辆",
  tx1808026: "离线车辆数",
  tx1808027: "准驾车辆",
  tx1808028: "所属区域",
  tx1808029: "车辆数量",
  tx18080301: "司机数量",
  tx18080302: "用户数量",
  tx18080303: "车辆数量",
  tx18080304: "均值",
  tx18080305: "在线司机",
  tx18080306: "在线用户",
  tx18080307: "时长(小时)",
  tx18080308: "车队评分排名TOP10",
  tx18080309: "车队报警排名TOP10",
  tx18080310: "车队百公里报警数排名TOP10",
  tx18080311: "车队10小时报警数排名TOP10",
  tx18080312: "车辆评分排名TOP10",
  tx18080313: "车辆报警排名TOP10",
  tx18080314: "车辆百公里报警数排名TOP10",
  tx18080315: "车辆10小时报警数排名TOP10",
  tx18080316: "司机评分排名TOP10",
  tx18080317: "司机报警排名TOP10",
  tx18080318: "司机百公里报警数排名TOP10",
  tx18080319: "司机10小时报警数排名TOP10",

  // 报表中心-车辆报表
  tx1808031: "车辆报表",
  tx1808032: "企业车辆月度报表",
  tx1808033: "车辆上线率统计",
  tx1808034: "车辆安全报表",
  tx1808035: "平均速度(km/h)",
  tx1808036: "所属车队",
  tx1808037: "车辆颜色",
  tx1808038: "行驶时长",
  tx1808039: "最高速度",
  // 报表中心-报警报表
  tx1808041: "报警处理报表",
  tx1808042: "报警时长报表",
  tx1808043: "报警次数报表",
  tx1808044: "报警报表",
  tx1808045: "总里程(公里)",
  tx1808046: "警报数/百公里",
  tx1808047: "车道偏离1级报警(报警总数)",
  tx1808048: "车道偏离1级报警(报警数/百公里)",
  tx1808049: "车道偏离2级报警(报警总数)",
  tx1808050: "车道偏离2级报警(报警数/百公里)",
  tx1808051: "前向碰撞1级报警(报警总数)",
  tx1808052: "前向碰撞1级报警(报警数/百公里)",
  tx1808053: "前向碰撞2级报警(报警总数)",
  tx1808054: "前向碰撞2级报警(报警数/百公里)",
  tx1808055: "行人碰撞1级报警(报警总数)",
  tx1808056: "行人碰撞1级报警(报警数/百公里)",
  // 报表中心-车辆报表-日评分
  tx1808201: "综合统计",
  tx1808202: "里程(公里)",
  tx1808203: "报警数量(次)",
  tx1808204: "百公里报警(次)",
  tx1808205: "小时报警(次)",
  tx1808206: "车队内排名",
  tx1808207: "评分排名",
  tx1808208: "百公里报警排名",
  tx1808209: "里程排名",
  tx1808210: "报警排名",
  tx1808211: "小时报警排名",
  tx1808212: "时长排名",
  // 平台设置
  tx1809001: "Platform Settings",
  // 平台设置 - 报警设置
  tx1809011: "Alarm settings",
  tx1809012: "Alarm Popup Enable/Disable",
  tx1809013: "Video Popup Enable/Disable",
  tx1809014: "Dangerous Vehicles Popup  Enable/Disable",
  tx1809015: "Check On-post Popup  Enable/Disable",
  tx1809016: "Warning Sound Enable/Disable",
  // 服务到期提醒
  tx1810001: "Service Expiration Reminder",
  tx1810002: "Expired vehicle Query",
  tx1810003: "Expired Vehicle List",
  tx1810004: "Vehicle Renewal Settings",

  // 指令树
  tx1900100: "Message",
  tx1900101: "Send Text Msg",
  tx1900200: "Active safety",
  tx1900201: "ADAS Settings",
  tx1900201_XB: "ADAS Settings(xiang)",
  tx1900202: "DSM Settings",
  tx1900202_XB: "DSM Settings(xiang)",
  tx1900203: "Blind Area settings",
  tx1900204: "Vehicle Monitor System",
  tx1900205: "Driver Comparison",
  tx1900206: "Driver Capture",
  tx1900207: "Driver information",
  tx1900208: "Terminal Settings",
  tx1900209: "Terminal Connection",
  tx1900210: "Phone Number",
  tx1900211: "Location Report ",
  tx1900212: "Alarm",
  tx1900213: "Take Photos",
  tx1900214: "Vehicle",
  tx1900215: "CAN Bus",
  tx1900216: "Module Chip",
  tx1900217: "Video Params",
  tx1900218: "Sleep Wake-up",
  tx1900219: "Tire Pressure params",
  tx1900300: "AV Command",
  tx1900301: "Multi-media list",
  tx1900302: "Download Video",
  tx1900400: "Terminal Control",
  tx1900401: "Terminal Attributes",
  // tx1900402: '终端控制',
  tx1900403: "Online Upgrade",
  tx1900404: "Door Lock Control",
  tx1900405: "AV transit",
  tx1900500: "Other Commands",
  tx1900501: "F3 peripheral polling",
  tx1900502: "Cut Off Oil/Electricity",
  tx1900503: "Scheduled Video/Photo Upload",
  tx1900504: "DVR Query",
  // 批量指令-多媒体
  tx1900601: "Multi-media Settings",
  tx1900602: "Voice recording",
  tx1900603: "Voice Monitoring",
  tx1900604: "Photo Shoot",
  tx1900605: "AV Channel List",
  tx1900606: "AV params",
  tx1900607: "Channel AV params",
  tx1900608: "Mute Video Alarm",
  tx1900609: "Video Analysis Alarm",
  tx1900610: "Special Alarm Video",
  tx1900611: "Passengers",
  tx1900612: "Fatigue Level Threshold",
  tx1900613: "Live Straming Encode Format",
  tx1900614: "Live Video Resolution",
  tx1900615: "Live Video Key Frame Rate",
  tx1900616: "live Video Taregt Key Frame Rate",
  tx1900617: "live video target rate(kbps)",
  tx1900618: "Storage Encode",
  tx1900619: "Storage video Resolution",
  tx1900620: "Storage Video Key Frame Rate",
  tx1900621: "Storage Target Frame Rate(f/s)",
  tx1900622: "Storage Target Video Rate(kbps)",
  tx1900623: "OSD Caption Settings",
  tx1900624: "Audio Output",

  // 特殊报警录像参数
  tx1900625: "Special Event Video Storage Threshold",
  tx1900626: "Percentage of total disk threshold settings",
  tx1900627: "Special Event Video Recording Duration ",
  tx1900628: "Special Alarm Video Start Mark ",

  // 视频报警屏蔽设置
  tx1900629: "Video Signal Lost Alarm",
  tx1900630: "Video Signal Block Alarm",
  tx1900631: "Storage Disk Error",
  tx1900632: "Other Terminal Error Alarms",
  tx1900633: "Passenger Overload Alarm",
  tx1900634: "Abnormal Drive Behavior Alarm",
  tx1900635: "Event Video Stroage Insufficient Alarm",

  // 录音参数
  tx1900640: "Voice Command",
  tx1900641: "Voice Recording ",
  tx1900642: "Save Types",
  tx1900643: "Realtime Upload",
  tx1900644: "Save Types",
  tx1900645: "Voice sampling rate",

  // 语音监听
  tx1900650: "Send Types",
  tx1900651: "Callback Monitoring",
  tx1900652: "Callback Calling",
  tx1900653: "Phone Number",
  tx1900654: "Monitoring Duration",

  // 批量指令-属性查询
  tx1900701: "Query Attribute",
  tx1900702: "Terminal Attributes",
  tx1900703: "AV Attributes",
  tx1900704: "Adas Peripheral Status",
  tx1900705: "Adas Peripheral Info",
  tx1900706: "External Device ID",
  tx1900707: "Working Status",
  tx1900708: "Warning Status",
  tx1900709: "Company Name",
  tx1900710: "Model",
  tx1900711: "Firmware Version",
  tx1900712: "Software Version",
  tx1900713: "Device ID",
  tx1900714: "Client Code",

  tx1900720: "Input AV eEncode Type",
  tx1900721: "Inout Audio Channel",
  tx1900722: "Input Audio Sample Ratio",
  tx1900723: "Input Audio Bit",
  tx1900724: "Audio Frame Length",
  tx1900725: "Support Audio Out Put Or Not",
  tx1900726: "Video Encode Formate",
  tx1900727: "Terminal Support Max Audio Channels",
  tx1900728: "Terminal Support Max Video Channels",

  // 批量指令-定位
  tx1900740: "Location",
  tx1900741: "Check On-post",
  tx1900742: "Tracking",

  // 指令form
  tx1900001: "Text Display Type",
  tx1900002: "SOS",
  tx1900003: "Terminal Screen Display",
  tx1900004: "Terminal TTS Speaks",
  tx1900005: "Ad Screen Display",

  /** adas - 苏标 */
  tx1901000: "Speed Threshold",
  tx1901001: "One Time Auto Take Photo Shots",
  tx1901002: "Alarm Volume",
  tx1901003: "One Photo Shoot Interval",
  tx1901004: "Active Photo Strategy",
  tx1901005: "Photo Resolution",
  tx1901006: "Auto Timing Photo Interval ",
  tx1901007: "Video Resolution",
  tx1901008: "Auto Photo Distance Interval",
  tx1901009: "Enable Warning",
  tx1901010: "Enable Event Warning",
  tx1901011: "Obstacle Warning Distance Threshold",
  tx1901012: "Obstacle Warning Speed Threshold ",
  tx1901013: "Obstacle Warning Video Recording Duration",
  tx1901014: "Obstacle Warning Photo Quantity",
  tx1901015: "Obstacle Warning Photo Interval",
  tx1901016: "FCLW Period",
  tx1901017: "FCLW Times Threshold",
  tx1901018: "FCLW Speed Level Threshold",
  tx1901019: "FCLW Event Video Recording Time ",
  tx1901020: "FCLW Event Photo Quantity",
  tx1901021: "FCLW Photo Interval",
  tx1901022: "LDW Level Speed Threshold",
  tx1901023: "LDW Video Recording Time",
  tx1901024: "LDW Photo Quantity",
  tx1901025: "LDW Photo Interval",
  tx1901026: "FCW Time Threshold",
  tx1901027: "FCW Speed Level Threshold",
  tx1901028: "FCW Video Recording Time",
  tx1901029: "FCW Photo Quantity",
  tx1901030: "FCW Photo Interval",
  tx1901031: "PCW Time Threshold",
  tx1901032: "PCW Speed Threshold",
  tx1901033: "PCW Video Recording Time",
  tx1901034: "PCW Photo Quantity",
  tx1901035: "PCW Photo Interval",
  tx1901036: "HMW Threshold",
  tx1901037: "HMW Level Threshold",
  tx1901038: "HMW Video Recording Time",
  tx1901039: "HMW Photo Quantity",
  tx1901040: "HMW Photo Interval",
  tx1901041: "Road Sign Warning Photo Quantity",
  tx1901042: "Road Sign Warning Photo Interval",

  /** adas - 湘标 */
  tx1901101: "Alarm Prompt Volume",
  tx1901102: "Auto Timing Photo Interval ",
  tx1901103: "Auto Timing Photo Distance Interval ",
  tx1901104: "One Time Auto Take Photo Shots",
  tx1901105: "One Photo Shoot Interval",
  tx1901106: "LDW video recording time",
  tx1901107: "LDW photo quantity",
  tx1901108: "LDW photo interval",
  tx1901109: "Forward collision warning time threshold",
  tx1901110: "Forward collision warning video recording time",
  tx1901111: "Forward collision warning photo quantity",
  tx1901112: "Forward collision warning photo interval",
  tx1901113: "PCW time threshold",
  tx1901114: "PCW speed threshold",
  tx1901115: "PCW video recording time",
  tx1901116: "PCW photo quantity",
  tx1901117: "PCW photo interval",
  tx1901118: "HMW threshold",
  tx1901119: "HMW video recording time",
  tx1901120: "HMW photo quantity",
  tx1901121: "HMW photo interval",
  tx1901122: "LDW Link upload main stream video channel",
  tx1901123: "LDW Link upload Sub stream video channel",
  tx1901124: "LDW Link upload Shot channel",
  tx1901125: "FCW Link upload main stream video channel",
  tx1901126: "FCW Link upload Sub stream video channel",
  tx1901127: "FCW Link upload Shot channel",
  tx1901128: "PCW Link upload main stream video channel",
  tx1901129: "PCW Link upload Sub stream video channel",
  tx1901130: "PCW Link upload Shot channel",
  tx1901131: "HMW Link upload main stream video channel",
  tx1901132: "HMW Link upload Sub stream video channel",
  tx1901133: "HMW Link upload Shot channel",

  /** DSM - 苏标 */
  tx1902001: "Driver Recognition Warning Trigger",
  tx1902002: "Driver Fatigue Warning Level Speed Threshold",
  tx1902003: "Driver Fatigue Warning Video Recording Time",
  tx1902004: "Driver Fatigue Warning Photo Quantity",
  tx1902005: "Driver Fatigue Warning Photo Interval",
  tx1902006: "Call Warning Period Interval",
  tx1902007: "Call Warning Level Speed Threshold",
  tx1902008: "Call Warning Video Recording Time",
  tx1902009: "Call Warning Driver Face Photo Quantity",
  tx1902010: "Call Warning Driver Face Photo Interval",
  tx1902011: "Smoking Warning Period Condition  ",
  tx1902012: "Smoking Warning Level Speed Threshold",
  tx1902013: "Smoking Warning Video Recording Time",
  tx1902014: "Smoking Warning Face Photo Quantity ",
  tx1902015: "Smoking Warning Face Photo Interval ",
  tx1902016: "Distraction ALM SPD LEVEL THOLD",
  tx1902017: "Distriction Warning Video Recording Duration",
  tx1902018: "Distriction Warning Face Photo Quantity",
  tx1902019: "Distrcition Warning Face Photo Interval",
  tx1902020: "Abnormal Driving Level Speed Threshold",
  tx1902021: "Abnormal Driving Video Recording Duration",
  tx1902022: "Abnormal Driving Warning Photo Quantity",
  tx1902023: "Abnormal Driving Warning Photo Interval",

  /** DSM - 湘标 */
  tx1902101: "Smoking warning period interval",
  tx1902102: "Call warning period interval",
  tx1902103: "Driver Fatigue Warning photo interval",
  tx1902104: "Call warning video recording time",
  tx1902105: "Call warning driver face photo quantity",
  tx1902106: "Call warning driver face photo interval",
  tx1902107: "Smoking warning face photo interval ",
  tx1902108: "Not looking ahead warning video recording time",
  tx1902109: "Not looking ahead warning photo quantity",
  tx1902110: "Not looking ahead warning photo interval",
  tx1902111: "Camera deviation warning video recording time",
  tx1902112: "Camera deviation warning photo quantity",
  tx1902113: "Camera deviation warning photo interval",
  tx1902114: "Not wearing seatbelt warning video recording time",
  tx1902115: "Not wearing seatbelt warning photo quantity",
  tx1902116: "Not wearing seatbelt warning photo interval",
  tx1902117: "Driver Fatigue Warning Link upload main stream video channel",
  tx1902118: "Driver Fatigue Warning Link upload Sub stream video channel",
  tx1902119: "Driver Fatigue Warning Link upload Shot channel",
  tx1902120: "Call warning Link upload main stream video channel",
  tx1902121: "Call warning Link upload Sub stream video channel",
  tx1902122: "Call warning Link upload Shot channel",
  tx1902123: "Smoking warning Link upload main stream video channel",
  tx1902124: "Smoking warning Link upload Sub stream video channel",
  tx1902125: "Smoking warning Link upload Shot channel",
  tx1902126: "Not looking ahead warning Link upload main stream video channel",
  tx1902127: "Not looking ahead warning Link upload Sub stream video channel",
  tx1902128: "Not looking ahead warning Link upload Shot channel",
  tx1902129: "Camera deviation warning Link upload main stream video channel",
  tx1902130: "Camera deviation warning Link upload Sub stream video channel",
  tx1902131: "Camera deviation warning Link upload Shot channel",
  tx1902132: "Playing Mobile Warning Link upload main stream video channel",
  tx1902133: "Playing Mobile Warning Link upload Sub stream video channel",
  tx1902134: "Playing Mobile Warning Link upload Shot channel",
  tx1902135: "Not wearing seatbelt warning Link upload main stream video channel",
  tx1902136: "Not wearing seatbelt warning Link upload Sub stream video channel",
  tx1902137: "Not wearing seatbelt warning Link upload Shot channel",

  /** 盲区参数 */
  tx1903001: "Backward Proximity Warning Time Threshold",
  tx1903002: "Side-Backward Proximity Warning Time Threshold",

  /** 车辆监测系统参数 - 湘标 */
  tx1904101: "Overloading warning video recording time",
  tx1904102: "Overloading warning photo quantity",
  tx1904103: "Overloading warning photo interval",
  tx1904104: "Overloading warning Link upload main stream video channel",
  tx1904105: "Overloading warning Link upload Sub stream video channel",
  tx1904106: "Overloading warning Link upload Shot channel",

  /** 批量设置驾驶员抓拍参数 - 湘标 */
  tx1905101: "Offline facial comparison switch",
  tx1905102: "Face comparison (DSM face image) success threshold",
  tx1905103: "Face comparison (mobile facial image) success threshold",
  tx1905104: "Enable voice broadcast comparison results",

  /** 批量设置驾驶员抓拍参数 - 湘标 */
  tx1906101: "ACC capture enable",
  tx1906102: "ACC capture start speed",
  tx1906103: "ACC capture speed duration",
  tx1906104: "Swipe card capture enable",
  tx1906105: "Swipe card capture start speed",
  tx1906106: "Swipe card capture speed duration",
  tx1906107: "Timing capture enable",
  tx1906108: "Timing capture start speed",
  tx1906109: "Timing capture speed duration",
  tx1906110: "Timing capture interval",
  tx1906111: "Leaving/returning capture enable",
  tx1906112: "Leaving/returning capture start speed",
  tx1906113: "Leaving/returning capture speed duration",
  tx1906114: "Facial Image Address Protocol",
  tx1906115: "Driver image",
  tx1906116: "Driver image source",

  tx1908001: "URL",
  tx1908002: "Dialup Point Name",
  tx1908003: "Dialup Username",
  tx1908004: "Dialup Password",
  tx1908005: "Server Address",
  tx1908006: "TCP Port",
  tx1908007: "UDP Port",
  tx1908008: "Manufacturer ID",
  tx1908009: "Hardware version",
  tx1908010: "Firmware version",
  tx1908011: "Connect with server(min)",

  tx1909000: "Polling duration",
  tx1909001: "Forward and reverse polling",
  tx1909002: "Load polling",
  tx1909003: "Temperature sensor polling",
  tx1909004: "Liquid level sensor polling",
  tx1909005: "Fuel consumption sensor polling",
  tx1909006: "Humidity sensor polling",
  tx1909007: "Mileage sensor polling",
  tx1909008: "External IO controller polling",
  tx1909009: "Tire pressure sensor polling",
  tx1909010: "Original vehicle OBD polling",
  tx1909011: "Work hour polling",
  tx1909012: "Acceleration detector polling",
  tx1909013: "SIM card function polling",
  tx1909014: "Pressure sensor polling",
  tx1909015: "Base station positioning polling",
  tx1909016: "Vibration sensor polling",
  tx1909017: "Engine speed polling",
  tx1909018: "Industry Information Terminal Polling",
  tx1909019: "RFID polling",
  tx1909020: "Transport certificate IC card reader polling",
  tx1909021: "IO input detection polling",
  tx1909022: "WIFI positioning polling",
  tx1909023: "Car navigation display screen polling",

  tx1910001: "Disconnect oil and electricity",
  tx1910002: "Turn on oil and electricity",

  /** 终端参数 */
  tx1911001: "Heartbeat Interval",
  tx1911002: "TCP Response Overtime",
  tx1911003: "TCP Resend Times",
  tx1911004: "UDP Response Overtime",
  tx1911005: "UDP Resend Times",
  tx1911006: "SMS Response Overtime",
  tx1911007: "SMS Resend Times",
  tx1911008: "Server TCP Port",
  tx1911009: "Server UDP Port",

  tx1911010: "Platform Phone No.",
  tx1911011: "Platform SMS Phone No.",
  tx1911012: "Listen-in Phone No.",
  tx1911013: "Platform Privilege SMS No.",
  tx1911014: "Reset Phone No.",
  tx1911015: "Restore Factory Settings No.",
  tx1911016: "Receiving SMS Alarm No.",
  tx1911017: "Phone Monitoring Strategy",
  tx1911018: "Max-duration per Call",
  tx1911019: "Max-duration in the Month",

  tx1911020: "Upload Strategy",
  tx1911021: "Upload Method",
  tx1911022: "Unlogin Time Interval",
  tx1911023: "Unlogin Distance Interval",
  tx1911024: "Sleep-Mode Time Interval",
  tx1911025: "Sleep-Mode Distance Interval",
  tx1911026: "SOS Time Interval",
  tx1911027: "SOS Distance Interval",
  tx1911028: "Default Time Interval",
  tx1911029: "Default Distance Interval",
  tx1911030: "Angle upload degree",
  tx1911031: "Illegal displacement threshold",

  tx1911032: "Max Speed",
  tx1911033: "Overspeed Duration",
  tx1911034: "Continuous driving duration",
  tx1911035: "Daily driving duration ",
  tx1911036: "Min rest duration",
  tx1911037: "Max parking duration",

  tx1911038: "Image/Video Quality",
  tx1911039: "Tint",
  tx1911040: "Light",
  tx1911041: "Saturation",
  tx1911042: "Contrast",
  tx1911043: "Timing Photo",
  tx1911044: "Fixed Distance Photo",

  tx1911045: "Vehicle Dashboard Mileage",
  tx1911046: "Administrative Area",
  // tx1911047: '车辆所在市域',

  tx1911048: "CANBUS CH1 data collection interval",
  tx1911049: "CANBUS CH1 data collection interval",
  tx1911050: "CANBUS CH2 data collection interval",
  tx1911051: "CANBUS CH2 data collection interval",
  tx1911052: "CANBUS ID singal collection settings",

  tx1911053: "GNSS Location Mode",
  tx1911054: "GNSS Baud Rate",
  tx1911055: "GNSS module location details output frequency",
  tx1911056: "GNSS module location details collection frequency",
  tx1911057: "GNSS module location details upload method",
  tx1911058: "GNSS module location details upload settings",

  tx1911059: "AV Params Settings",
  tx1911060: "AV channel List Setting",
  tx1911061: "Single Video CH Param Settings",
  tx1911062: "Special Warning Video Param Settings",
  tx1911063: "Mute video Related Warning",
  tx1911064: "Graphic Analyse Warning Params Settings",
  tx1911065: "Terminal Wakeup Mode Setttings",

  tx1911066: "Terminal Wakeup Mode Setttings",
  tx1911067: "Wakeup Conditions",
  tx1911068: "Timing Wakeup Date",
  tx1911069: "Timing Wake-up Time Period",
  tx1911070: "Period 1",
  tx1911071: "Period 2",
  tx1911072: "Period 3",
  tx1911073: "Period 4",

  tx1912001: "Command ",
  tx1912002: "Disable AV",
  tx1912003: "Apply Selected Rate",

  tx1913001: "Tire Model",
  tx1913002: "Tire Pressure Unit",
  tx1913003: "Normal Tire Pressure",
  tx1913004: "Tire Abnormal Threshold",
  tx1913005: "Slow Tire Gas Leak Threshold ",
  tx1913006: "Low Tire Pressure Threshold",
  tx1913007: "High Tire Pressure Threshold",
  tx1913008: "High Temp Threshold",
  tx1913009: "Voltage Threshold",
  tx1913010: "Timing Upload Time Threshold",

  // form表单extra提示
  /** 苏标 */
  tx2001000: "Range 0 to 60,000m, 0 means don't take photo",
  tx2001001: "Range 0 to 60 (sec), 0  means disable video recording",
  tx2001002: "Range 0 to 10, 0 means disable photo",
  tx2001003: "Range 0 to 8. 0 means mute",
  tx2001004: "Ranges 0 to 220 (km/h), speed over threshold is level 2 warning, otherwise is level 1 warning",
  tx2001005: "Range 0 to 60 km/h, applied to lane departure,forwardd collision,frequent change lanes. Only when speed is over the threshold, Alarms can be triggered",
  tx2001006: "Range 1 to 10",
  tx2001007: "Range 0 to 3600(sec)",
  tx2001008: "Range 3 to 10",
  tx2001009: "Range 30 to 120",
  tx2001010: "Unit 100ms, range 1 to 10",
  tx2001011: "Unit 100ms, range 10 to 50",
  tx2001012: "Unit 100ms, range 10 to 50, use national standard value 27",
  tx2001013: "Range 0 to 10",
  tx2001014: "Range 1 to 5",
  tx2001015: "Unit 100ms, range 10 to 50，Default value 30",
  tx2001016: "Unit km/h, Range 0 to 220 (km/h), speed over this threshold is warning, otherwise disable warning",
  tx2001017: "Unit 100ms, range 10 to 50, Default value 10",
  tx2001020: "195/65R16 91V 12char Default 900R20 ",
  tx2001021: "Range 0 to 100 (unit %, reach cold gas pressure value)",
  tx2001022: "Unit Centigrade",
  tx2001023: "Range 0 to 100(%)",

  /** 湘标 */
  tx2002000: "Range 0 to 60, Only applicable to lane departure and collision warning for vehicles ahead. Indicates that the alarm function is only activated when the vehicle speed exceeds this threshold",
  tx2002001: "Range 0 to 60(km/h) Indicates that the alarm function is only activated when the vehicle speed exceeds this threshold",
  tx2002002: "Range 60 to 60000(S)",
  tx2002003: "Unit 100ms, Range 1 to 5",
  tx2002004: "Unit %, Range 1 to 100",
  tx2002005: "Unit 100ms, range 10 to 50, use national standard value 27",
  tx2002006: "Unit km/h, range 0 to 60, Default value 10, Indicates that the alarm function is only activated when the vehicle speed exceeds this threshold",

  tx2009000: "If the selected 0, not be issued",
  tx2009001: "Peripheral message length",

  // 用户
  tx210000: "User",
  tx210001: "Username",
  // tx210002: '角色',
  tx210003: "Created By",
  tx210004: "Login Name",
  tx210005: "Password",
  tx210006: "Confirm Password",
  tx210007: "Old Password",
  tx210008: "New Password",
  tx210009: "Confirmed password and new password do not match",

  // 角色
  tx220000: "Role",
  tx220001: "Role Name",
  tx220003: "Role Info",

  // 菜单
  tx230000: "Menu",
  tx230001: "Menu Desc",
  tx230002: "Menu Info",
  tx230003: "Upper Menu",
  tx230004: "Menu Type",
  tx230005: "Level",
  tx230006: "Routing Address",
  tx230007: "Function Type",
  tx230008: "Sorting",
  tx230009: "Icon",
  tx230010: "Enable",
  tx230011: "Menu key",

  // 传感器
  tx240001: "Sensor Type",
  tx240002: "Key",
  tx240003: "Value",
  tx240004: "Reporting Time",
  tx240005: "Rotation State",
  tx240006: "Rotation Direction",
  tx240007: "Rotation Speed (/mis)",
  tx240008: "Rotation Duration (mis)",
  tx240009: "Total Pulse Quantity",
  tx240010: "load Conditions",
  tx240011: "Total Running Times (H)",
  tx240012: "Loading Times",
  tx240013: "Load Weight（kg）",
  tx240014: "Loading/Unloading Weight（kg）",
  tx240015: "AD Value",
  tx240016: "Floating Zero Point",
  tx240017: "Relative Load Capacity",
  tx240018: "Weight Unit",
  tx240019: "Important Data Identification",

  // 地图操作
  tx300001: "Map Functions",
  tx300002: "Drag Map",
  tx300003: "Check Vehicles",
  tx300004: "Default Location",
  tx300005: "Zoom in",
  tx300006: "Zoom out",
  tx300007: "Measure Distance",
  tx300008: "Map Switching",

  // 电子围栏
  tx310000: "Rectangle Geo-fence",
  tx310001: "Polygon Geo-fence",
  tx310002: "Circle Geo-fence",
  tx310003: "Key Point Geo-fence",
  tx310004: "Route Geo-fence",
  tx310005: "Area Name",
  tx310006: "Route Name",
  tx310007: "Line Segment Name",
  tx310008: "Enter Geo-fence",
  tx310009: "Leave Geo-fence",
  tx310010: "Time Period Setting",
  tx310011: "Speed Limit Settings",
  tx310012: "Speed Limit (km/h)",
  tx310013: "Speed Limit",
  tx310014: "Delay Warning",
  tx310015: "Coordinate",
  tx310016: "Original coordinate",
  tx310017: "Alarm to Driver",
  tx310019: "Alarm to Platform",
  tx310021: "Based on Time",
  tx310022: "Delay Warning(S)",
  tx310023: "Key Point Name",
  tx310024: "Area Radius",
  tx310025: "Limited time",
  tx310026: "Must Arrive",
  tx310027: "Must Leave",
  tx310028: "Open-door",
  tx310029: "Geo-fence Name",
  tx310030: "Geo-fence Type",
  tx310031: "Rule Type",
  tx310032: "Bind Vehicle",
  tx310033: "Radius",
  tx310034: "Turn on",
  tx310035: "Turn off",
  tx310036: "Gather",
  tx310037: "Not Gather",
  tx310038: "Allow door opening",
  tx310039: "Allow door opening",
  tx310040: "Line width",
  tx310041: "Deviation Delay Warning",
  tx310042: "Road Section Setting",
  tx310043: "Drive Duration",
  tx310044: "Overtime Threshold",
  tx310045: "Insufficient Threshold",
  tx310046: "Angle Longitude",
  tx310047: "Angle Altitude",
  tx310048: "Send Type",
  tx310049: "Send Result",
  tx310050: "Send Time",
  tx310051: "to Platform",
  tx310052: "to Vehicle",
  tx310053: "Communication Module",
  tx310054: "GNSS",

  //用户设置
  tx320000: "Setting",
  tx320001: "Video Settings",
  tx320002: "Enable Realtime Alarm Popup",
  tx320003: "Enable Alarm Video Popup",
  tx320004: "Enable Dangerous Vehicles Popup",
  tx320005: "Enable Check On-post Popup",
  tx320006: "Enable Warning Sound",
  tx320007: "Default Video Rate",
  tx320008: "Default Request AV",
  tx320009: "Auto Close Video",
  tx320010: "Auto Close Listen-in",
  tx320011: "Auto Close Duration",
  tx320012: "Map Settings",
  tx320013: "Default",
  tx320014: "Vehicle Labels",
  tx320015: "Realtime Status Display Type",
  tx320016: "LEVEL",
  tx320017: "Center Longitude",
  tx320018: "Center Latitude",
  tx320019: "Vehicle Icon Type",
  tx320020: "Default Chls Quantity",
  tx320021: "Vehicle Expiration Duration（Mon）",
  tx320022: "Please input a positive integer",

  //日志管理
  tx330000: "Log No.",
  tx330002: "Operation results",
  tx330003: "Operator",
  tx330004: "Operation Time",
  tx330005: "Operation Content",
  tx330007: "Last month",
  tx330008: "Past 3 Months",
  tx330009: "Past 6 Months",
  tx330010: "Command Type",
  tx330011: "Command Content",
  tx330012: "Serial No.",
  tx330013: "Execution Results",

  //规则中心-报警设置
  tx340001: "Sound",
  tx340002: "Pop",
  tx340003: "Pop Text Color",
  tx340004: "Duration Stat",
  tx340005: "Auto Send Text",
  tx340006: "Upload Attachment",
  tx340007: "Add Alarm Settings",
  tx340008: "Alarm Code",
  tx340009: "Live Video Linkage",
  tx340010: "Photo Trigger",
  tx340011: "Enable Config",
  tx340012: "Enable Warning",
  tx340013: "Personalization",
  tx340014: "Modify Alarm Settings",
  tx340015: "Alarm Setting Details",
  tx340016: "Batch Save",
  tx340017: "This Org No Permission",

  // 指数权重设置
  tx350001: "ID",
  tx350002: "Warning Source",
  tx350003: "Warning Type",
  tx350004: "Alarm Quantity",
  tx350005: "Quantity Weight",
  tx350006: "Vehicle Speed",
  tx350007: "Speed Weight",
  tx350008: "Index Class",
  tx350009: "Index Weight",
  tx350010: "Index Weight Details",
  tx350011: "Max Deduction Value",
  tx350012: "Daily Score",
  tx350013: "Daily Score Details",
  tx350014: "Alarm Score",
  tx350015: "Score Rules",
  tx350025: "Min QTY Alarms",
  tx350026: "Max QTY Alarm",
  tx350027: "Each deduction value",

  // 实时评分设置
  tx350016: "Speed weight setting",
  tx350017: "Hazard level setting",
  tx350018: "Hazard level Name",
  tx350019: "Min score",
  tx350020: "Max score",
  tx350021: "Min Speed",
  tx350022: "Max Speed",
  tx350023: "Speed weight",
  tx350024: "Scoring rules",

  // 地图类型
  tx360001: "Autonavi",
  tx360002: "Baidu",
  tx360003: "Google",

  // 右键指令
  tx370001: "Location Upload Interval(S)",
  tx370002: "Location Valid Date(S)",
  tx370003: "Start Listen-in",
  tx370004: "Stop Listen-in",
  tx370005: "Unrecognized Current Driver",

  // 平台参数
  tx380005: "Map Vehicle Icon Type",
  tx380006: "Map Vehicle Labels",
  tx380007: "Realtime Status Display Type",
  tx380008: "Realtime AV Status Notification",
  tx380009: "Map Center Longitude",
  tx380010: "Map Center Latitude",
  tx380011: "Map LEVEL",
  tx380012: "Data Refresh Interval",
  tx380013: "Baidu Map Key",
  tx380014: "Baidu Map Secret",
  tx380015: "AutoNavi Map Key",
  tx380016: "AutoNavi Map Secret",
  tx380017: "Web no operation duration",
  tx380018: "Connected Duration without transit  ",
  tx380019: "Video Streaming Rate",
  tx380020: "Video Resources Types",
  tx380021: "Video Automatic Closed duration",
  tx380022: "Audio docking duration",
  tx380023: "Default Chls Quantity",
  tx380024: "Map Default LEVEL",
  tx380025: "Platform Name",
  tx380026: "Default Map",
  tx380027: "Data Multiplier",
  tx380028: "Platform ",

  // 车标设置
  tx390001: "Defalt Icon",
  tx390002: "Modification Time",
  tx390003: "Vehicle Icon",
  tx390004: "Vehicle Icon Detail",
  tx390005: "Vehicle Icon Type",
  tx390006: "Vehicle Icon Address",

  // 上级查岗
  tx400001: "Roll call target",
  tx400002: "Roll call type",
  tx400003: "Roll call question",
  tx400004: "Answer",

  // 批量指令下发-其他指令-行车记录仪
  tx500001: "Pulse coefficient",
  tx500002: "D0",
  tx500003: "Vehicle VIN number",
  tx500004: "D1",
  tx500005: "Implementation time",
  tx500006: "D2",
  tx500007: "Pulse coefficient",
  tx500008: "D3",
  tx500009: "Vehicle classification",
  tx500010: "D4",
  tx500011: "D5",
  tx500012: "D6",
  tx500013: "D7",

  // 电话本设置
  tx600001: "PhoneBook",
  tx600002: "Sign",
  tx600003: "Phone number",
  tx600004: "Contacts",
  // 信息菜单设置
  tx601001: "Info Menu",
  tx601002: "Menu ID",
  tx601003: "Menu Content",

  // 事件报告设置
  tx602001: "Event Report",
  tx602002: "Event ID",
  tx602003: "Event content",
  // 提问下发
  tx603001: "Question Dispatch",
  tx603002: "Send Type",
  tx603003: "Candidate Answers1",
  tx603004: "Question content",
  tx603005: "Candidate Answers2",
  tx603006: "Candidate Answers3",
  tx603007: "AnswersId",
  tx603008: "Answers",
  tx603009: "Is Correct",

  // 清理缓存
  tx604001: "Cleanup successful",
  tx604002: "DB index",
  tx604003: "Redis key",
  tx604004: "Hash term",
  tx604005: "Types",
  tx604006: "Please select the DB index",
  tx604007: "Please input the Redis key",
  tx604008: "Please input the hash item",
  tx604009: "Please select the type",

  // 驾驶时长
  tx605001: "Submitted successfully",
  tx605002: "Min test drive duration",
  tx605003: "Please input the min test drive duration",
  tx605004: "Max test drive duration",
  tx605005: "Please input the max test drive duration",

  // 跟踪
  tx606001: "Start tracking",
  tx606002: "Stop tracking",
  tx606003: "Positioning interval",
  tx606004: "Tracking duration",

  //拍照
  tx606005: "CMD Type",
  tx606010: "Shooting Interval",
  tx606012: "Quality",
  tx606013: "Save as",
  tx606016: "Video duration",
}