import React, { useState, useEffect } from 'react'
import { CloseOutlined } from '@ant-design/icons'
import { Access, Info } from 'components'
import { InfoItems } from 'components/info';
import classnames from 'classnames'
import { observer } from 'mobx-react'
import { webAPIResponse } from 'server/web/index.globals';
import { userStore, monitorStore, broadcastStory, positionStory, userSettingStore } from 'store'
import './inforWindowContent.less'
import { message, Tooltip } from 'antd';
import { getLangMessage as f } from 'util/comm'
import Lnglats from 'util/lnglats'
import { toJS } from "mobx"

interface inforWindowContentProps {
  id?: string
  onClose?: () => void
  info: webAPIResponse.vehiclesInfo | null
  /** 是否展示操作栏
   * @default true
   */
  showTool?: boolean
}

function InforWindowContent(props: inforWindowContentProps) {
  const { info } = props
  const [visible, setVisible] = useState<boolean>(true)

  useEffect(() => {
    setVisible(!userStore.isConcrete)
  }, [userStore.isConcrete])

  useEffect(() => {
    setVisible(props.showTool === false ? false : true)
  }, [props.showTool])


  const item: InfoItems[] = [{
    children: [
      { dataIndex: 'driver', intlTitle: 'tx110004', description: '驾驶员', render: (value: any) => <span>{value}</span> },
      { dataIndex: 'depName', intlTitle: 'tx010002', description: '车组', render: (value: any) => <span>{value}</span> },
      { dataIndex: 'simNo', intlTitle: 'tx060001', description: 'SIM卡号', render: (value: any) => <span>{value}</span> },
      { dataIndex: 'velocity', intlTitle: 'tx010004', description: '速度', render: (value: any) => <span>{value} km/h</span> },
      { dataIndex: 'directionDesc', intlTitle: 'tx010006', description: '方向', render: (value: any) => <span>{value}</span> },
      { dataIndex: 'altitude', intlTitle: 'tx060009', description: '海拔', render: (value: any) => <span>{value}</span> },
      { dataIndex: 'mileage', intlTitle: 'tx060005', description: '里程', render: (value: any) => <span>{value} km</span> },
      { dataIndex: 'sendTime', intlTitle: 'tx000102', description: '时间', row: 2, render: (value: any) => <span>{value}</span> },
      // { dataIndex: 'location', intlTitle: 'tx000106', description: '地址', row: 3, render: (value: any) => <span>{ value }</span> },
      {
        dataIndex: '_', intlTitle: 'tx000106', description: '地址', row: 3,
        render: (_value: any, record: any) => {
          const records = toJS(record)
          const recordStr = JSON.stringify(records)
          return <Lnglats lnglat={recordStr && recordStr != '{}' ? [records?.longitude, records?.latitude] : [0.0, 0.0]} />
        }
      },
      {
        dataIndex: 'statusDesc', intlTitle: 'tx010101', description: '状态', row: 3, render: (_value: any, item: any) => {
          const value = userSettingStore.baseSetting.displayStateType === '0' ?
            item.statusDesc : item.signalStateDesc
          return <span>{value}</span>
        }
      },
      { dataIndex: 'alarmStatusDesc', intlTitle: 'tx020027', description: '报警', row: 3, render: (value: any) => <span style={{ color: '#f5222d' }}>{value}</span> },
    ]
  }]

  const [btns] = useState<Array<{
    type: string
    intl: string
  }>>([
    {
      type: 'video',
      intl: 'tx000114'
    },
    {
      type: 'playback',
      intl: 'tx000115'
    },
    {
      type: 'trajectory',
      intl: 'tx000113'
    },
    {
      type: 'photograph',
      intl: 'tx020021'
    },
    {
      type: 'call',
      intl: 'tx000054'
    },
    // {
    //   type: 'search',
    //   intl: ''
    // },
  ])

  /** 按钮点击事件 */
  const btnClick = (key: string): void => {
    const { info } = props
    if (info) {
      if (!info.online && key !== 'trajectory') {
        message.warning({
          content: f({ id: 'tx083004', description: '车辆不在线' }),
          key: 100
        })
        return
      }

      switch (key) {
        case 'video':
          monitorStore.getVehiclesInfoAjax(info.vehicleId, '3')
          broadcastStory.doubleClickGetVideo({
            plateNo: info.plateNo,
            simNo: info.simNo,
            passway: info.videoChannel ? info.videoChannel.split(',').map(item => Number(item)) : [],
            vehicleId: info.vehicleId,
          })
          break;
        case 'trajectory':
          monitorStore.getVehiclesInfoAjax(info.vehicleId, '2')
          monitorStore.updateVechileInfo({
            plateNo: info.plateNo,
            simNo: info.simNo,
            passway: info.videoChannel ? info.videoChannel.split(',').map(item => Number(item)) : [],
            vehicleId: info.vehicleId,
          })
          monitorStore.updateTabsKey('2')
          break;
        case 'playback':
          monitorStore.getVehiclesInfoAjax(info.vehicleId, '4')
          monitorStore.updateVechileInfo({
            plateNo: info.plateNo,
            simNo: info.simNo,
            passway: info.videoChannel ? info.videoChannel.split(',').map(item => Number(item)) : [],
            videoChannelDesc: info.videoChannelDesc ? info.videoChannelDesc.split(',') : undefined,
            vehicleId: info.vehicleId,
          })
          monitorStore.updateTabsKey('4')
          break;
        case 'photograph':
          positionStory.showPhotographModal()
          break;
        case 'call':
          positionStory.showCallModal()
          break;
        case 'line':
          break;
        case 'search':
          break;
      }
    }
  }

  const close = () => {
    if (props.onClose) {
      props.onClose()
    }
  }

  return (
    <div className='tx-infowindow'>

      <div className='infowindow-header'>
        <div className='header-title'>
          {info ? info.plateNo : '渝C110110'}
          <span>
            {info ? info.plateColorName || info.plateColor : ''}
          </span>
        </div>
        <div>
          <CloseOutlined onClick={close} />
        </div>
      </div>
      <div className='infowindow-content'>
        <Info row={3} items={item} data={info || {}} />
      </div>
      {
        visible ?
          <div className='infowindow-footer'>
            {
              btns.map((item, index: number) => {
                return(<Access auth={item.type} key={index}><Tooltip key={`title_` + index} title={f({ id: item.intl })}>
                <div
                  key={`infowindow-btns-${index}`}
                  className={classnames('btn-item', item.type)}
                  onClick={() => {
                    btnClick(item.type)
                  }}>
                </div>
              </Tooltip></Access>) 
              })}
          </div>
          : null
      }
    </div>
  )
}

export default observer(InforWindowContent)
