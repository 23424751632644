/*
 * @Description:
 * @Author: 谢永红
 * @Date: 2020-08-21 10:11:33
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-27 09:48:30
 */
export default {
  // 公用-操作
  tx000001: "确定",
  tx000002: "取消",
  tx000003: "新增",
  tx000004: "删除",
  tx000005: "修改",
  tx000006: "查询",
  tx000007: "操作",
  tx000008: "重置",
  tx000009: "导出",
  tx000010: "查看",
  tx000011: "列设置",
  tx000012: "发送",
  tx000013: "下载",
  tx000014: "位置追踪",
  tx000015: "音频监听",
  tx000016: "语音对话",
  tx000017: "调度下发",
  tx000018: "最小化",
  tx000019: "窗口化",
  tx000020: "最大化",
  tx000021: "批量修改",
  tx000022: "导入",
  tx000023: "模板下载",
  tx000024: "操作成功",
  tx000025: "选择全部",
  tx000026: "证据批量下载",
  tx000027: "修改密码",
  tx000028: "退出登录",
  tx000029: "授权",
  tx000030: "撤回",
  tx000031: "查看结果",
  tx000032: "停用",
  tx000033: "解除锁定",
  tx000034: "启用",
  tx000035: "全屏",
  tx000036: "退出全屏",
  tx000037: "获取参数",
  tx000038: "设置参数",
  tx000039: "全选",
  tx000040: "更多",
  tx000041: "清除所有轮询",
  tx000042: "清除",
  tx000043: "撤回平台",
  tx000044: "撤回终端",
  tx000045: "重新下发终端",
  tx000046: "显示下级",
  tx000047: "共享",
  tx000048: "保存",
  tx000049: "参数查询",
  tx000050: "下发命令获取信息",
  tx000051: "用户中心",
  tx000052: "企业信息",
  tx000053: "关闭",
  tx000054: "点名",
  tx000055: "上传",
  tx000056: "刷新",
  tx000057: "参数",
  tx000058: "显示无效位置",
  tx000059: "继续",
  tx000060: "导入成功",
  tx000061: "撤回下发",
  tx000062: "批量删除",
  tx000063: "湘标指令",
  tx000064: "苏标指令",
  tx000065: "编辑",
  tx000066: "清理",
  tx000067: "语音对讲",
  // 公用-显示
  tx000100: "{value}{value2}",
  tx000101: "序号",
  tx000102: "时间",
  tx000103: "开始时间",
  tx000104: "结束时间",
  tx000105: "备注",
  tx000106: "地址",
  tx000107: "暂无数据",
  tx000108: "名称",
  tx000109: "全部",
  tx000110: "位置",
  tx000111: "基础信息",
  tx000112: "实时位置",
  tx000113: "轨迹回放",
  tx000114: "实时视频",
  tx000115: "视频回放",
  tx000312: "音频回放",
  tx000311: "试驾过程",
  tx000313: "试驾信息",
  tx000116: "实时数据",
  tx000117: "是否自动刷新",
  tx000118: "选择全部",
  tx000119: "类型",
  tx000120: "车队/车辆扣分TOP5",
  tx000121: "安全大数据",
  tx000122: "天",
  tx000123: "昨天",
  tx000124: "今天",
  tx000125: "最近三天",
  tx000126: "数据区间",
  tx000127: "总时长",
  tx000128: "轨迹异常",
  tx000129: "轨迹完整率",
  tx000130: "轨迹分段",
  tx000131: "轨迹里程",
  tx000132: "开始",
  tx000133: "截止",
  tx000134: "时长",
  tx000135: "上级查岗",
  tx000136: "危险车辆提醒",
  tx000137: "结束时间不能小于开始时间",
  tx000138: "时间范围不能超过3天",
  tx000139: "创建日期",
  tx000140: "商砼大数据",
  tx000141: "实时车辆数据",
  tx000142: "实时方量数据",
  tx000143: "实时供货数据",
  tx000144: "实时运货单数据",
  tx000145: "实时报警车辆信息",
  tx000146: "预排车辆",
  tx000147: "休息车辆",
  tx000148: "已派车辆",
  tx000149: "维修车辆",
  tx000150: "预计总方量",
  tx000151: "完成总方量",
  tx000152: "运输中方量",
  tx000153: "工地数",
  tx000154: "供应中工地数",
  tx000155: "运输车次",
  tx000156: "运货单号",
  tx000157: "运单方量",
  tx000158: "供应工地",
  tx000159: "任务运单数据",
  tx000160: "在线率",
  tx000161: "危险率",
  tx000162: "时间轴",
  tx000163: "录像文件",
  tx000164: "发送调度信息",
  tx000165: "下发结果",
  tx000166: "查询时间",
  tx000167: "任务执行条件",
  tx000168: "下发状态",
  tx000169: "下发时间",
  tx000170: "执行状态",
  tx000171: "执行时间",
  tx000172: "级",
  tx000173: "超高危",
  tx000174: "高危",
  tx000175: "危险",
  tx000176: "较危",
  tx000177: "正常",
  tx000178: "类型名称",
  tx000179: "EN",
  tx000180: "修改日期",
  tx000181: "修改人",
  tx000182: "设置围栏",
  tx000183: "围栏设置",
  tx000184: "规则类型",
  tx000185: "是",
  tx000186: "否",
  tx000187: "围栏详情",
  tx000188: "时间单位",
  tx000189: "工地",
  tx000190: "运输量",
  tx000191: "中文显示",
  tx000192: "英文显示",
  tx000193: "显示排序",
  tx000194: "请绘制围栏",
  tx000195: "初始化中",
  tx000196: "上传中",
  tx000197: "上传成功",
  tx000198: "轨迹分段",
  tx000199: "不连续轨迹",
  tx0001100: "加载中...",

  // 公用-提示
  tx000201: "请选择{value}",
  tx000202: "请输入{value}",
  tx000203: "请输入{value}{value2}",
  tx000204: "请输入{value}/{value2}",
  tx000205: "最少输入{value}个字符",
  tx000206: "最多输入{value}个字符",
  tx000207: "请选择{value}/{value2}",
  tx000208: "请选择{value}{value2}",
  tx000209: "{value}选择",
  tx000210: "最多可勾选{value}个",
  tx000211: "{value}不能为空",
  tx000212: "总共 {value} 项",
  tx000213: "共选择{value}项{value2}",
  tx000214: "确定{value}吗?",
  tx000215: "点击上传{value}",
  tx000216: "只能上传jpg或png格式的文件",
  tx000217: "搜索指令",
  tx000218: "该车辆无视频通道",
  tx000219: "时间范围不能超过24小时",
  tx000220: "输入需要搜索的地址",
  tx000221: "没有找到相关的地点",
  tx000222: "不能大于{value}",
  tx000223: "不能小于{value}",
  tx000224: "搜索菜单",
  tx000225: "请联系管理员配置权限菜单",
  tx000226: "请先在左侧地图绘制路段",
  tx000227: "等待发送",
  tx000228: "请最少添加一条规则",
  tx000229: "报警次数区间存在重叠",
  tx000230: "存在重复的报警次数值",
  tx000231: "报警类型重复",
  tx000232: "报警最小次数不得大于报警最大次",
  tx000233: "最小速度值不得大于最大速度值",
  tx000234: "最小分值不得大于最大分值",
  tx000235: "速度区间存在重叠",
  tx000236: "分值区间存在重叠",
  tx000237: "请输入1-60之间的值",
  tx000238: "请输入60-200之间的值",
  tx000239: "请输入{value} - {value1}",
  tx000240: "小数部分为{value} - {value1}位",
  tx000241: "只能上传xls格式的文件",
  tx000242: "请输入1到100之间的值",
  tx000243: "请输入0到100之间的值",
  tx000244: "请输入",

  // 车辆
  tx010000: "快速加车",
  tx010001: "车牌号",
  tx010002: "车队",
  tx010003: "颜色",
  tx010004: "速度",
  tx010005: "油量",
  tx010006: "方向",
  tx010007: "经度",
  tx010008: "纬度",
  tx010009: "原始经度",
  tx010010: "原始纬度",
  tx010011: "车牌颜色",
  tx010012: "前车速度",
  tx010013: "前车/行人距离",
  tx010014: "车辆状态",
  tx010015: "信息",
  tx010016: "车辆",
  tx010017: "车辆数",
  tx010018: "车辆总数",
  tx010019: "行驶总里程",
  tx010020: "车辆排名TOP10",
  tx010021: "车辆信息",
  tx010022: "最低速度",
  tx010023: "车辆编号",
  tx010024: "自编号",
  tx010025: "车籍地",
  tx010026: "行业类型",
  tx010027: "车辆类型",
  tx010028: "运输证号",
  tx010029: "业户名称",
  tx010030: "经营许可证号",
  tx010031: "经营范围",
  tx010032: "有效期",
  tx010033: "运输证照",
  tx010034: "行驶证信息",
  tx010035: "车架号",
  tx010036: "发动机号",
  tx010037: "使用性质",
  tx010038: "品牌型号",
  tx010039: "注册日期",
  tx010040: "有效期",
  tx010041: "行驶证照",
  tx010042: "保险单号",
  tx010043: "保险类型",
  tx010044: "保险公司",
  tx010045: "保险金额",
  tx010046: "折扣率",
  tx010047: "实际费用",
  tx010048: "代理人",
  tx010049: "代理电话",
  tx010050: "保养日期",
  tx010051: "下次保养日期",
  tx010052: "维修时间",
  tx010053: "维修费用",
  tx010054: "维修内容",
  tx010055: "车载终端厂商唯一编码",
  tx010056: "车载终端型号",
  tx010057: "车载终端编号",
  tx010058: "平台唯一编码",
  tx010059: "转向",
  tx010060: "使用类型",
  tx010061: "运行状态",
  tx010062: "位置数据",
  tx010063: "实时速度",

  // 车辆 - 状态
  tx010101: "状态",
  tx010102: "在线",
  tx010103: "离线",
  tx010104: "行驶",
  tx010105: "停车",
  tx010106: "报警",
  tx010107: "在线率",
  tx010108: "离线率",
  tx010109: "营运状态",
  tx010110: "在线车辆数",
  tx010111: "危险车辆数",
  tx010112: "车辆数",

  // 报警
  tx020000: "报警证据",
  tx020001: "报警来源",
  tx020002: "报警类型",
  tx020003: "报警时间",
  tx020004: "报警地点",
  tx020005: "报警等级",
  tx020006: "附件数",
  tx020007: "报警标志",
  tx020008: "偏离类型",
  tx020009: "道路标志识别类型",
  tx020010: "道路标志识别数据",
  tx020011: "胎压报警内容",
  tx020012: "报警处理",
  tx020013: "报警视频",
  tx020014: "报警图片",
  tx020015: "下发文本",
  tx020016: "下发内容",
  tx020017: "文本信息",
  tx020018: "常用文本",
  tx020019: "加为常用",
  tx020020: "发送命令",
  tx020021: "拍照",
  tx020022: "拍照信息",
  tx020023: "拍摄方式",
  tx020024: "摄像头",
  tx020025: "报警数据",
  tx020026: "媒体文件",
  tx020027: "报警",
  tx020028: "报警总数",
  tx020029: "24小时报警分布",
  tx020030: "实时报警车辆信息",
  tx020031: "车均报警",
  tx020032: "百公里报警",
  tx020033: "报警趋势",
  tx020034: "主要报警统计",
  tx020035: "报警类型分布",
  tx020036: "报警截止时间",
  tx020037: "报警处理结果",
  tx020038: "实时报警信息",
  tx020039: "驾驶行为异常报警",
  tx020040: "运输证信息",
  tx020041: "保险信息",
  tx020042: "维修信息",
  tx020043: "上级注册信息",
  tx020044: "报警标志",
  tx020045: "限速值（km/h）",
  tx020046: "超时驾驶时长（分钟）",
  tx020047: "超时驾驶类型",
  tx020048: "比对相似度",
  tx020049: "比对类型",
  tx020050: "拍摄张数",
  tx020051: "拍摄间隔(秒)",
  tx020052: "保存方式",
  tx020053: "图片尺寸",
  tx020054: "品质(1-10)",
  tx020055: "亮度(0~255)",
  tx020056: "对比度(0~127)",
  tx020057: "饱和度(0~127)",
  tx020058: "色度(0~255)",
  tx020059: "拍摄间隔",
  tx020060: "高级设置",
  tx020061: "报警信息",

  // 处理
  tx030001: "处理",
  tx030002: "处理状态",
  tx030003: "处理意见",
  tx030004: "已处理完毕",
  tx030005: "将来处理",
  tx030006: "不做处理",
  tx030007: "处理成功",
  tx030008: "处理失败",
  tx030009: "去处理",
  tx030010: "处理方式",
  tx030011: "处理时间",
  tx030012: "处理人",

  // 督办
  tx040001: "督办截止时间",
  tx040002: "督办级别",
  tx040003: "督办级别经办人",
  tx040004: "报警督办",
  tx040005: "上级平台报警督办消息",
  tx040006: "报警督办ID",
  tx040007: "督办人",
  tx040008: "报警督办信息",

  // 联系人
  tx050001: "电子邮件",
  tx050002: "联系电话",
  tx050003: "联系人",
  tx050004: "企业编号",

  // 设备
  tx060001: "SIM卡号",
  tx060002: "在线",
  tx060003: "脉冲速度",
  tx060004: "GPS定位时间",
  tx060005: "里程",
  tx060006: "当日里程",
  tx060007: "累积里程",
  tx060008: "定位",
  tx060009: "海拔",
  tx060010: "信号强度",
  tx060011: "多媒体类型",
  tx060012: "上传时间",
  tx060013: "延迟(s)",
  tx060014: "消耗(s)",
  tx060015: "记录仪速度",
  tx060016: "有效性",
  tx060017: "终端号",
  tx060018: "终端类型",
  tx060019: "终端手机号",
  tx060020: "设备状态",
  tx060021: "绑定SIM卡",
  tx060022: "全部通道",
  tx060023: "所有存储器",
  tx060024: "主存储器",
  tx060025: "灾备存储器",
  tx060026: "绑定状态",
  tx060027: "终端",
  tx060028: "出厂号",
  tx060029: "安装时间",
  tx060030: "安装工",
  tx060031: "硬件型号",
  tx060032: "软件版本号",
  tx060033: "SIM卡",
  tx060034: "卡状态",
  tx060035: "运营商",
  tx060036: "定位地址",
  tx060037: "绑定终端",
  tx060038: "终端信息",
  tx060039: "终端ID",
  tx060040: "终端型号",
  tx060041: "GNSS模块属性",
  tx060042: "通信模块属性",

  // 视频
  tx070001: "媒体类型",
  tx070002: "码流类型",
  tx070003: "存储器类型",
  tx070004: "文件大小(KB)",
  tx070005: "通道",
  tx070006: "通道号",
  tx070007: "事件类型",
  tx070008: "播放",
  tx070009: "暂停",
  tx070010: "停止",
  tx070011: "进度",
  tx070012: "资源类型",
  tx070013: "音频",
  tx070014: "视频",
  tx070015: "音频或音视频",
  tx070016: "音视频",
  tx070017: "主码流",
  tx070018: "子码流",
  tx070019: "全部播放",
  tx070020: "全部停止",
  tx070021: "通道",
  tx070022: "立即拍照",
  tx070023: "定时拍照",
  tx070024: "第一路",
  tx070025: "第二路",
  tx070026: "第三路",
  tx070027: "第四路",
  tx070028: "第五路",
  tx070029: "打开定时拍照",
  tx070030: "上传照片",
  tx070031: "定时拍照参数设置信息",
  tx070032: "音视频类型",
  tx070033: "文件大小",
  tx070034: "已传文件大小",

  // axios
  tx08_0: "等待服务器响应",
  tx08_1: "请求超时",
  tx08_2: "上传文件最大为10M",
  tx080001: "上传失败",
  tx08400: "错误的请求",
  tx08401: "未经授权，请重新登录",
  tx08403: "禁止访问",
  tx08404: "没有找到资源",
  tx08405: "不支持当前请求类型",
  tx08408: "请求超时",
  tx08429: "请求超过次数限制",
  tx08500: "内部服务错误",
  tx08502: "网关错误",
  tx08503: "服务不可用",
  tx08504: "网关超时",
  tx081001: "请求中必须至少包含一个有效文件",
  tx081024: "服务器繁忙，请稍后重试",
  tx083004: "车辆不在线",
  tx084004: "其他用户正在回放视频，请一会尝试",
  tx0810104: "新密码与旧密码不能相同",
  tx0810105: "新密码与确认密码不一致",
  tx0810107: "旧密码错误",
  tx0810108: "企业名称已存在",
  tx0810109: "存在子企业",
  tx0810010: "用户名或密码错误",
  tx0810011: "您的账号或密码已输错5次以上，输错10次后账号将被锁定无法登录",
  tx0810012: "您的账号已被锁定无法登录，请找管理员解除锁定",
  tx0810101: "用户账号已存在",
  tx0810102: "账号密码不一致",
  tx0810110: "存在用户信息",
  tx0810111: "存在车组信息",
  tx0810112: "存在司机信息",
  tx0810113: "存在SIM卡信息",
  tx0810114: "存在终端信息",
  tx0810115: "存在角色信息",

  tx0820001: "未获取到码表数据",
  tx0820002: "常用文本已存在，添加失败",
  tx0820003: "常用文本为空，添加失败",
  tx0820004: "常用文本添加失败",
  tx0820005: "常用文本删除失败",
  tx0820006: "报警类型重复",
  tx0820007: "该类型下的键重复",
  tx0823001: "车标数据不存在",
  tx0823002: "车辆状态图标已存在，不能重复添加",

  tx0830001: "车辆不存在",
  tx0830003: "解绑失败",
  tx0830004: "SIM卡号已存在",
  tx0830005: "SIM卡已绑定",
  tx0830006: "终端已存在",
  tx0830007: "终端已绑定",
  tx0830008: "自编号已存在",
  tx0830009: "simNo已存在",
  tx0830010: "车牌号已存在",
  tx0830011: "车辆添加失败",
  tx0830012: "车辆修改失败",
  tx0830013: "车辆删除失败",
  tx0830014: "绑定失败",
  tx0830015: "终端不存在",
  tx0830016: "司机添加失败",
  tx0830017: "司机修改失败",
  tx0830018: "司机已绑定车辆，删除失败",

  tx0831001: "添加失败",
  tx0831002: "修改失败",
  tx0831003: "无法删除，车队下有车辆",
  tx0831004: "SIM卡号已存在",
  tx0831005: "SIM卡已绑定",
  tx0831006: "终端已存在",

  tx0860001: "查询无数据",
  tx0860002: "车辆位置信息获取失败",
  tx0860003: "导出缺少response对象",
  tx0860004: "导出数据为null",
  tx0860005: "导出excel缺少标题栏",
  tx0860006: "时间范围不能超过7天",
  tx0860007: "时间范围不能超过2个月",
  tx0860008: "请选择时间范围",
  tx0860009: "请选择要查询的车辆",
  tx0860010: "请检查数据是否重复",
  tx0860011: "电子围栏下存在车辆,不能删除",
  tx0865009: "找不到此车辆信息",

  tx0882003: "请求缺少参数",
  tx0883004: "车辆不在线",
  tx0883006: "无数据",
  tx0884003: "切换失败,多个用户在看",
  "tx08-1": "系统繁忙~请稍后再试~",
  "tx08-2": "系统维护中~请稍后再试~",
  "tx08-3": "参数类型解析异常",
  "tx08-4": "运行SQL出现异常",
  "tx08-5": "空指针异常",
  "tx08-6": "无效参数异常",
  "tx08-7": "请求类型异常",
  "tx08-8": "加载资源出错",
  "tx08-9": "统一验证参数异常",
  "tx08-10": "操作异常",
  "tx08-11": "Mapper类转换异常",
  "tx08-12": "验证码校验失败",
  "tx08-16": "文件不存在",

  // 登录login
  tx090001: "登录",
  tx090002: "正在登录中...",
  tx090003: "登录成功",
  tx090004: "APP下载二维码",
  tx090005: "账号",
  tx090006: "密码",
  tx090007: "匹配成功",
  tx090008: "匹配失败",
  tx090009: "大写锁定已打开",
  tx090010: "小程序下载二维码",

  // 平台信息
  tx100001: "主动安全监控平台",
  tx100002: "天巡主动安全监控平台",
  tx100003: "天砼智能调度系统",
  tx100004: "天砼智能调度系统",
  tx100005: "商砼智能调度系统",

  // 司机
  tx110001: "司机姓名",
  tx110002: "疲劳程度",
  tx110003: "司机",
  tx110004: "驾驶员",
  tx110005: "身份证号",
  tx110006: "从业资格证",
  tx110007: "发证机构",
  tx110008: "押运员姓名",
  tx110009: "准驾车型",
  tx110010: "出生日期",
  tx110011: "IC卡号",
  tx110012: "年审日期",
  tx110013: "发证日期",
  tx110014: "过期日期",
  tx110015: "监管机构",
  tx110016: "监督电话",
  tx110017: "驾驶证",
  tx110018: "身份证正面",
  tx110019: "身份证反面",
  tx110020: "驾驶证信息",
  tx110021: "绑定司机",
  tx110022: "主驾",
  tx110023: "司机照片",
  tx110024: "年龄",
  tx110025: "性别",

  // 监听
  tx120001: "监听",
  tx120002: "监听信息",
  tx120003: "监听类型",
  tx120004: "监听电话",

  // 企业
  tx130000: "企业",
  tx130001: "所属企业",
  tx130002: "车队",
  tx130003: "车队排名TOP10",
  tx130004: "企业名称",
  tx130005: "上级企业",
  tx130006: "企业数据授权",
  tx130007: "未授权",
  tx130008: "已授权",
  tx130009: "解除授权",
  tx130101: "上级车队",

  // 商砼
  tx140001: "商砼",
  // 任务运单
  tx140101: "送货单号",
  tx140102: "本次方量",
  tx140103: "送货状态",
  tx140104: "正供工地地址",

  // 量词
  tx150001: "辆",
  tx150002: "次",
  tx150003: "公里",
  tx150004: "小时",
  tx150005: "秒",
  tx150006: "米",
  tx150007: "张",
  tx150008: "度",
  tx150009: "分钟",

  // 表单验证
  tx1600001: "数字",
  tx1600002: "汉字",
  tx1600003: "字母",
  tx1600004: "请输入正确的电话号码",
  tx1600005: "请输入最多有一位小数的数字",
  tx1600006: "请输入正确的身份证号",
  tx1600007: "请输入有效的数字",
  tx1600050:
    "修改车队所属企业会对车队下的车辆归属造成影响，请同步修改车辆的所属企业和车队",
  tx1600051: "选择时间不能超过30分钟",
  tx1600052: "查询单个时间范围限制为两个月，查询多个时间范围限制为七天",

  // 首页
  tx1700001: "操控指数报警",
  tx1700002: "平稳指数报警",
  tx1700003: "危险驾驶报警",
  tx1700004: "疲劳驾驶报警",
  tx1700005: "非专注驾驶报警",
  tx1700006: "前向碰撞报警",
  tx1700007: "抽烟报警",
  tx1700008: "车道偏离报警",
  tx1700009: "超速报警（终端）",
  tx1700010: "接打电话报警",
  tx1700011: "驾驶员异常报警",
  tx1700012: "行人碰撞报警",

  // 菜单
  tx1801001: "首页",
  tx1802001: "监控大屏",
  tx1802002: "商砼大屏",
  tx1803001: "综合监控",
  tx1803021: "监控中心",
  tx1803002: "视频监控",
  tx1804001: "报表中心",
  tx1804002: "商砼查询",
  tx1804003: "订单运单管理",
  // 管理中心
  tx1805001: "管理中心",
  // 管理中心 - 信息管理
  tx1805011: "车管中心",
  tx1805012: "企业信息",
  tx1805013: "车队管理",
  tx1805014: "终端管理",
  tx1805015: "SIM卡管理",
  tx1805016: "司机管理",
  tx1805017: "车辆管理",
  tx1805018: "摄像头管理",
  tx18050180: "摄像头",
  tx18050181: "摄像头名称",
  tx18050182: "摄像头UID",
  tx18050183: "设备用户",
  tx18050184: "设备密码",
  tx18050185: "创建时间",
  // 管理中心 - 规则管理
  tx1805021: "规则中心",
  tx1805022: "批量指令",
  tx1805023: "电子围栏",
  tx1805024: "报警设置",
  // 管理中心 - 用户管理
  tx1805031: "用户管理",
  tx1805032: "用户信息",
  tx1805033: "权限管理",
  tx1805034: "角色管理",
  tx1805035: "企业管理",
  tx1805036: "菜单管理",
  // 管理中心 - 日志管理
  tx1805041: "日志管理",
  tx1805042: "操作日志",
  tx1805043: "终端日志",
  // 管理中心 - 系统管理
  tx1805051: "系统管理",
  tx1805052: "数据字典",
  tx1805053: "日评分设置",
  tx1805054: "平台参数",
  tx1805055: "视频服务器参数",
  tx1805056: "实时评分设置",
  tx1805057: "企业参数",
  tx1805058: "车标设置",
  tx1805059: "HTML5视频",
  tx1805060: "试驾参数设置",
  tx1805071: "缓存清理",
  // 管理中心 - 续时管理
  tx1805061: "续时管理",
  tx1805062: "车辆续时记录",
  tx1805063: "车辆续时",
  tx1805064: "续时币管理",
  tx1805065: "续时币充值",
  tx1805066: "充值数",
  tx1805067: "账户续时币",
  tx1805068: "续时时长",
  tx1805069: "续时方式",
  tx18050610: "消耗费用",
  tx18050611: "续时",
  tx18050612: "批量续时",
  tx18050613: "服务到期状态",
  tx18050614: "剩余/已到期天数",
  tx18050615: "服务到期日期",
  tx18050616: "安装日期",
  tx18050617: "续时企业",
  tx18050618: "剩余续时币",
  tx18050619: "续时币数",
  tx18050620: "服务到期列表",
  tx18050621: "选择续时时间",
  tx18050622: "已过期",
  tx18050623: "近三天",
  tx18050624: "近七天",
  tx18050625: "近三十天",
  tx18050626: "续时车辆数",
  tx18050627: "支付方式",
  tx18050628: "共需支付",
  tx18050629: "自定义月数",
  tx18050630: "账户余额不足",
  tx18050631: "不能给本企业充值",

  // 查询中心
  tx1806001: "查询中心",
  // 查询中心 - 报警查询
  tx1806011: "报警查询",
  tx1806012: "司机报警查询",
  tx1806013: "报警证据查询",
  tx1806014: "批量报警处理",
  tx1806015: "报警处理记录查询",
  tx1806016: "实时报警处理",
  tx1806017: "报警地图",
  tx1806018: "报警次数查询",
  tx1806019: "报警时长查询",
  tx18060110: "行车报警查询",
  tx18060111: "车辆报警查询",

  // 查询中心 - 行车查询
  tx1806021: "行车查询",
  tx1806022: "上下线状态查询",
  tx1806023: "上下线记录查询",
  tx1806024: "进出围栏记录查询",
  tx1806025: "插卡拔卡记录查询",
  tx1806026: "车辆工作时长查询",
  // 查询中心 - 位置查询
  tx1806031: "位置查询",
  tx1806032: "轨迹完整率查询",
  tx1806033: "历史位置查询",
  tx1806034: "GPS台账查询",
  tx1806035: "行驶里程查询",
  tx1806036: "里程只差查询",
  tx1806037: "定位合格率查询",

  // 查询中心 - 多媒体查询
  tx1806041: "多媒体查询",
  tx1806042: "设备牌照查询",
  tx1806043: "视频播放记录查询",
  tx1806044: "服务器录像查询",
  tx1806045: "多媒体上传记录查询",
  tx1806046: "音视频流量统计",
  // 查询中心 - 传感器查询
  tx1806051: "传感器查询",
  tx1806052: "传感器信息查询",
  tx1806053: "正反转传感器信息查询",
  tx1806054: "载重传感器查询",
  // 查询中心 - 传感器查询
  tx1806061: "状态查询",
  // 查询中心 - 车辆报表
  tx1806071: "在线状态查询",
  tx1806072: "工作率查询",
  tx1806073: "上线率查询",
  tx1806074: "日评分查询",
  tx1806075: "月评分查询",
  tx1806076: "在线状态",
  tx1806077: "最近上线时间",
  tx1806078: "最近上线位置",
  tx1806079: "统计时间",
  tx1806080: "上线时间",
  tx1806081: "离线时间",
  tx1806082: "工作时间（h）",
  tx1806083: "统计时间",
  tx1806084: "工作率（%）",
  tx1806085: "报警分布",
  tx1806086: "评分",
  tx1806087: "监控对象",
  tx1806088: "运营类型",
  tx1806089: "行驶里程",
  tx1806090: "行驶次数",
  tx1806091: "日均行驶时长",
  tx1806092: "平均长度",
  tx1806093: "报警数",
  tx1806094: "综合得分",
  tx1806095: "报警分布(百公里)",
  tx1806096: "报警分布(每小时)",

  // 查询中心 - 位置查询 - 定位合格率查询
  tx1806101: "统计日期",
  tx1806102: "定位总数",
  tx1806103: "错位定位数",
  tx1806104: "定位数据合格率",

  // 查询中心 - 位置查询 - 轨迹完整率查询
  tx1806111: "连续里程(km)",
  tx1806112: "轨迹总里程(km)",
  tx1806113: "轨迹完整率(%)",

  //查询中心-试驾查询
  tx1806121: "试驾查询",

  // 商砼车
  tx1807001: "商砼车",
  // 商砼车 - 调度管理
  tx1807011: "调度管理",
  tx1807012: "调度中心",
  tx1807013: "车辆状态查询",
  tx1807014: "订单管理",
  // 商砼车 - 信息管理
  tx1807021: "商砼管理",
  tx1807022: "商砼站管理",
  tx1807023: "客户工地管理",
  // 商砼车 - 报表管理
  tx1807031: "报表管理",
  tx1807032: "客户发货查询",
  tx1807033: "车辆送货查询",
  tx1807034: "车辆转向查询",
  tx1807035: "车辆报警查询",
  // 报表中心
  tx1808001: "报警统计",
  // 报表中心-司机报表
  tx1808011: "司机报表",
  tx1808012: "企业司机月度报表",
  tx1808013: "司机安全报表",
  tx1808014: "拔插卡查询",
  tx1808015: "操作类型",
  tx1808016: "读卡结果",
  tx1808017: "驾驶时间(小时)",
  tx1808018: "昨天分数",
  tx1808019: "今天分数",
  // 报表中心-企业报表
  tx1808021: "报警统计",
  tx1808022: "企业安全报表",
  tx1808023: "企业上线率报表",
  tx1808024: "企业报表",
  tx1808025: "在线车辆",
  tx1808026: "离线车辆数",
  tx1808027: "准驾车辆",
  tx1808028: "所属区域",
  tx1808029: "车辆数量",
  tx18080301: "司机数量",
  tx18080302: "用户数量",
  tx18080303: "车辆数量",
  tx18080304: "均值",
  tx18080305: "在线司机",
  tx18080306: "在线用户",
  tx18080307: "时长(小时)",
  tx18080308: "车队评分排名TOP10",
  tx18080309: "车队报警排名TOP10",
  tx18080310: "车队百公里报警数排名TOP10",
  tx18080311: "车队10小时报警数排名TOP10",
  tx18080312: "车辆评分排名TOP10",
  tx18080313: "车辆报警排名TOP10",
  tx18080314: "车辆百公里报警数排名TOP10",
  tx18080315: "车辆10小时报警数排名TOP10",
  tx18080316: "司机评分排名TOP10",
  tx18080317: "司机报警排名TOP10",
  tx18080318: "司机百公里报警数排名TOP10",
  tx18080319: "司机10小时报警数排名TOP10",

  // 报表中心-车辆报表
  tx1808031: "车辆报表",
  tx1808032: "企业车辆月度报表",
  tx1808033: "车辆上线率统计",
  tx1808034: "车辆安全报表",
  tx1808035: "平均速度(km/h)",
  tx1808036: "所属车队",
  tx1808037: "车辆颜色",
  tx1808038: "行驶时长",
  tx1808039: "最高速度",
  // 报表中心-报警报表
  tx1808041: "报警处理报表",
  tx1808042: "报警时长报表",
  tx1808043: "报警次数报表",
  tx1808044: "报警报表",
  tx1808045: "总里程(公里)",
  tx1808046: "警报数/百公里",
  tx1808047: "车道偏离1级报警(报警总数)",
  tx1808048: "车道偏离1级报警(报警数/百公里)",
  tx1808049: "车道偏离2级报警(报警总数)",
  tx1808050: "车道偏离2级报警(报警数/百公里)",
  tx1808051: "前向碰撞1级报警(报警总数)",
  tx1808052: "前向碰撞1级报警(报警数/百公里)",
  tx1808053: "前向碰撞2级报警(报警总数)",
  tx1808054: "前向碰撞2级报警(报警数/百公里)",
  tx1808055: "行人碰撞1级报警(报警总数)",
  tx1808056: "行人碰撞1级报警(报警数/百公里)",
  // 报表中心-车辆报表-日评分
  tx1808201: "综合统计",
  tx1808202: "里程(公里)",
  tx1808203: "报警数量(次)",
  tx1808204: "百公里报警(次)",
  tx1808205: "小时报警(次)",
  tx1808206: "车队内排名",
  tx1808207: "评分排名",
  tx1808208: "百公里报警排名",
  tx1808209: "里程排名",
  tx1808210: "报警排名",
  tx1808211: "小时报警排名",
  tx1808212: "时长排名",
  // 平台设置
  tx1809001: "平台设置",
  // 平台设置 - 报警设置
  tx1809011: "报警设置",
  tx1809012: "实时报警信息弹窗启用/禁用",
  tx1809013: "报警视频弹窗启用/禁用",
  tx1809014: "危险车辆弹窗启用/禁用",
  tx1809015: "上级查岗弹窗启用/禁用",
  tx1809016: "报警声音提醒启用/禁用",
  // 服务到期提醒
  tx1810001: "服务到期提醒",
  tx1810002: "到期车辆查询",
  tx1810003: "到期车辆列表",
  tx1810004: "车辆续费设置",

  // 指令树
  tx1900100: "信息",
  tx1900101: "文本信息下发",
  tx1900200: "主动安全参数",
  tx1900201: "ADAS参数(苏)",
  tx1900201_XB: "ADAS参数(湘)",
  tx1900202: "DSM参数(苏)",
  tx1900202_XB: "DSM参数(湘)",
  tx1900203: "盲区参数",
  tx1900204: "车辆监测系统参数",
  tx1900205: "驾驶员比对参数",
  tx1900206: "驾驶员抓拍参数",
  tx1900207: "驾驶员信息",
  tx1900208: "终端参数",
  tx1900209: "终端连接参数",
  tx1900210: "电话号码参数",
  tx1900211: "位置汇报参数",
  tx1900212: "报警参数",
  tx1900213: "拍照照片参数",
  tx1900214: "车辆参数",
  tx1900215: "CAN总线参数",
  tx1900216: "北斗模块参数",
  tx1900217: "视频参数",
  tx1900218: "休眠唤醒参数",
  tx1900219: "胎压参数",
  tx1900300: "音视频指令",
  tx1900301: "查询音视频资源列表",
  tx1900302: "视频下载",
  tx1900400: "终端控制",
  tx1900401: "终端属性",
  // tx1900402: '终端控制',
  tx1900403: "在线升级",
  tx1900404: "门锁控制",
  tx1900405: "音视频传输控制",
  tx1900500: "其他指令",
  tx1900501: "F3外设轮询",
  tx1900502: "断油电指令",
  tx1900503: "定时上报视频及图片设置",
  tx1900504: "行车记录仪",
  // 批量指令-多媒体
  tx1900601: "多媒体",
  tx1900602: "录音",
  tx1900603: "语音监听",
  tx1900604: "拍照",
  tx1900605: "音视频通道列表设置",
  tx1900606: "音视频参数设置",
  tx1900607: "通道音视频参数设置",
  tx1900608: "视频报警屏蔽设置",
  tx1900609: "视频分析报警设置",
  tx1900610: "特殊报警录像参数",
  tx1900611: "车辆核载人数",
  tx1900612: "疲劳程度阈值",
  tx1900613: "实时流编码模式",
  tx1900614: "实时流分辨率",
  tx1900615: "实时流关键帧间隔(帧)",
  tx1900616: "实时流目标帧率(帧/s)",
  tx1900617: "实时流目标码率(kbps)",
  tx1900618: "存储流编码模式",
  tx1900619: "存储流分辨率",
  tx1900620: "存储流关键帧间隔(帧)",
  tx1900621: "存储流目标帧率(帧/s)",
  tx1900622: "存储流目标码率(kbps)",
  tx1900623: "OSD字母叠加设置",
  tx1900624: "音频输出",

  // 特殊报警录像参数
  tx1900625: "特殊报警录像存储阈值",
  tx1900626: "占用主存储阈值百分比",
  tx1900627: "特殊报警录像持续时间",
  tx1900628: "特殊报警标识起始时间",

  // 视频报警屏蔽设置
  tx1900629: "视频信号丢失报警",
  tx1900630: "视频信号遮挡报警",
  tx1900631: "存储单元故障报警",
  tx1900632: "其他视频设备故障报警",
  tx1900633: "客车超员报警",
  tx1900634: "异常驾驶行为报警",
  tx1900635: "特殊报警录像达到存储阈值报警",

  // 录音参数
  tx1900640: "录音命令",
  tx1900641: "录音时间",
  tx1900642: "保存方式",
  tx1900643: "实时上传",
  tx1900644: "保存本地",
  tx1900645: "音频采样率",

  // 语音监听
  tx1900650: "发送类型",
  tx1900651: "回拨监听",
  tx1900652: "回拨通话",
  tx1900653: "电话号码",
  tx1900654: "监听时长",

  // 批量指令-属性查询
  tx1900701: "属性查询",
  tx1900702: "终端属性查询",
  tx1900703: "音视频属性查询",
  tx1900704: "Adas外设状态",
  tx1900705: "Adas外设系统信息",
  tx1900706: "外设ID",
  tx1900707: "工作状态",
  tx1900708: "报警状态",
  tx1900709: "公司名称",
  tx1900710: "产品型号",
  tx1900711: "硬件版本号",
  tx1900712: "软件版本号",
  tx1900713: "设备ID",
  tx1900714: "客户代码",

  tx1900720: "输入音频编码方式",
  tx1900721: "输入音频声道数",
  tx1900722: "输入音频采样率",
  tx1900723: "输入音频采样位数",
  tx1900724: "音频帧长度",
  tx1900725: "是否支持音频输出",
  tx1900726: "视频编码格式",
  tx1900727: "终端支持的最大音频声道数",
  tx1900728: "终端支持的最大视频通道数",

  // 批量指令-定位
  tx1900740: "定位",
  tx1900741: "点名定位",
  tx1900742: "临时跟踪",

  // 指令form
  tx1900001: "显示类型",
  tx1900002: "紧急",
  tx1900003: "终端显示器显示",
  tx1900004: "终端TTS播读",
  tx1900005: "广告牌显示",

  /** adas - 苏标 */
  tx1901000: "报警判断速度阈值",
  tx1901001: "单次抓拍照片数目",
  tx1901002: "报警音量",
  tx1901003: "单次主动拍照间隔",
  tx1901004: "主动拍照策略",
  tx1901005: "拍照分辨率",
  tx1901006: "主动定时拍照间隔",
  tx1901007: "视频录制分辨率",
  tx1901008: "主动定距拍照间隔",
  tx1901009: "报警使能",
  tx1901010: "事件使能",
  tx1901011: "障碍物报警距离阈值",
  tx1901012: "障碍物报警分级速度阈值",
  tx1901013: "障碍物报警前后视频录制时间",
  tx1901014: "障碍物报警拍照张数",
  tx1901015: "障碍物报警拍照间隔",
  tx1901016: "频繁变道报警判断时间段",
  tx1901017: "频繁变道报警判断次数",
  tx1901018: "频繁变道报警分级速度阈值",
  tx1901019: "频繁变道报警前后视频录制时间",
  tx1901020: "频繁变道报警拍照张数",
  tx1901021: "频繁变道报警拍照间隔",
  tx1901022: "车道偏离报警分级速度阈值",
  tx1901023: "车道偏离报警前后视频录制时间",
  tx1901024: "车道偏离报警拍照张数",
  tx1901025: "车道偏离报警拍照间隔",
  tx1901026: "前向碰撞报警时间阈值",
  tx1901027: "前向碰撞报警分级速度阈值",
  tx1901028: "前向碰撞报警前后视频录制时间",
  tx1901029: "前向碰撞报警拍照张数",
  tx1901030: "前向碰撞报警拍照间隔",
  tx1901031: "行人碰撞报警时间阈值",
  tx1901032: "行人碰撞报警使能速度阈值",
  tx1901033: "行人碰撞报警前后视频录制时间",
  tx1901034: "行人碰撞报警拍照张数",
  tx1901035: "行人碰撞报警拍照间隔",
  tx1901036: "车距监控报警距离阈值",
  tx1901037: "车距监控报警分级速度阈值",
  tx1901038: "车距过近报警前后视频录制时间",
  tx1901039: "车距过近报警拍照张数",
  tx1901040: "车距过近报警拍照间隔",
  tx1901041: "道路标志识别拍照张数",
  tx1901042: "道路标志识别拍照间隔",

  /** adas - 湘标 */
  tx1901101: "报警提示音量",
  tx1901102: "主动定时拍照时间间隔",
  tx1901103: "主动定距拍照距离间隔",
  tx1901104: "单次主动拍照张数",
  tx1901105: "单次主动拍照时间间隔",
  tx1901106: "车道偏离预警前后视频录制时间",
  tx1901107: "车道偏离预警拍照张数",
  tx1901108: "车道偏离预警拍照间隔",
  tx1901109: "前方车辆碰撞预警时间阈值",
  tx1901110: "前方车辆碰撞预警前后视频录制时间",
  tx1901111: "前方车辆碰撞预警拍照张数",
  tx1901112: "前方车辆碰撞预警拍照间隔",
  tx1901113: "行人碰撞预警时间阈值",
  tx1901114: "行人碰撞预警使能速度阈值",
  tx1901115: "行人碰撞预警前后视频录制时间",
  tx1901116: "行人碰撞预警拍照张数",
  tx1901117: "行人碰撞预警拍照间隔",
  tx1901118: "前向车距过近预警距离阈值",
  tx1901119: "前向车距过近预警前后视频录制时间",
  tx1901120: "前向车距过近预警拍照张数",
  tx1901121: "前向车距过近预警拍照间隔",
  tx1901122: "车道偏离预警联动上传主码流视频通道",
  tx1901123: "车道偏离预警联动上传子码流视频通道",
  tx1901124: "车道偏离预警联动上传抓拍通道",
  tx1901125: "前方车辆碰撞预警联动上传主码流视频通道",
  tx1901126: "前方车辆碰撞预警联动上传子码流视频通道",
  tx1901127: "前方车辆碰撞预警联动上传抓拍通道",
  tx1901128: "行人碰撞预警联动上传主码流视频通道",
  tx1901129: "行人碰撞预警联动上传子码流视频通道",
  tx1901130: "行人碰撞预警联动上传抓拍通道",
  tx1901131: "前向车距过近预警联动上传主码流视频通道",
  tx1901132: "前向车距过近预警联动上传子码流视频通道",
  tx1901133: "前向车距过近预警联动上传抓拍通道",

  /** DSM - 苏标 */
  tx1902001: "驾驶员身份识别触发",
  tx1902002: "疲劳驾驶报警分级速度阈值",
  tx1902003: "疲劳驾驶报警前后视频录制时间",
  tx1902004: "疲劳驾驶报警拍照张数",
  tx1902005: "疲劳驾驶报警拍照间隔",
  tx1902006: "接打电话报警判断间隔",
  tx1902007: "接打电话报警分级速度阈值",
  tx1902008: "接打电话报警前后视频录制时间",
  tx1902009: "接打电话报警拍驾驶员面部特征照片张数",
  tx1902010: "接打电话报警拍驾驶员面部特征照片间隔",
  tx1902011: "抽烟报警判断间隔",
  tx1902012: "抽烟报警分级速度阈值",
  tx1902013: "抽烟报警前后视频录制时间",
  tx1902014: "抽烟报警拍驾驶员面部特征照片张数",
  tx1902015: "抽烟报警拍驾驶员面部特征照片间隔",
  tx1902016: "分神报警分级速度阈值",
  tx1902017: "分神报警前后视频录制时间",
  tx1902018: "分神报警拍驾驶员面部特征照片张数",
  tx1902019: "分神报警拍驾驶员面部特征照片间隔",
  tx1902020: "驾驶行为异常分级速度阈值",
  tx1902021: "驾驶行为异常视频录制时间",
  tx1902022: "驾驶行为异常拍照张数",
  tx1902023: "驾驶行为异常拍照间隔",

  /** DSM - 湘标 */
  tx1902101: "抽烟报警判断时间间隔",
  tx1902102: "接打手持电话报警判断时间间隔",
  tx1902103: "疲劳驾驶报警拍照间隔时间",
  tx1902104: "接打手持电话报警前后视频录制时间",
  tx1902105: "接打手持电话报警拍驾驶员面部特征照片张数",
  tx1902106: "接打手持电话报警拍驾驶员面部特征照片间隔时间",
  tx1902107: "抽烟报警拍驾驶员面部特征照片间隔时间",
  tx1902108: "不目视前方报警前后视频录制时间",
  tx1902109: "不目视前方报警拍照张数",
  tx1902110: "不目视前方报警拍照间隔时间",
  tx1902111: "摄像头偏离驾驶位常视频录制时间",
  tx1902112: "摄像头偏离驾驶位抓拍照片张数",
  tx1902113: "摄像头偏离驾驶位拍照间隔",
  tx1902114: "未系安全带驾驶报警前后视频录制时间",
  tx1902115: "未系安全带驾驶报警拍照张数",
  tx1902116: "未系安全带驾驶报警拍照间隔时间",
  tx1902117: "生理疲劳报警联动上传主码流视频通道",
  tx1902118: "生理疲劳报警联动上传子码流视频通道",
  tx1902119: "生理疲劳报警联动上传抓拍通道",
  tx1902120: "接打手持电话报警联动上传主码流视频通道",
  tx1902121: "接打手持电话报警联动上传子码流视频通道",
  tx1902122: "接打手持电话报警联动上传抓拍通道",
  tx1902123: "抽烟报警联动上传主码流视频通道",
  tx1902124: "抽烟报警联动上传子码流视频通道",
  tx1902125: "抽烟报警联动上传抓拍通道",
  tx1902126: "不目视前方联动上传主码流视频通道",
  tx1902127: "不目视前方联动上传子码流视频通道",
  tx1902128: "不目视前方联动上传抓拍通道",
  tx1902129: "摄像头偏离驾驶位联动上传主码流视频通道",
  tx1902130: "摄像头偏离驾驶位联动上传子码流视频通道",
  tx1902131: "摄像头偏离驾驶位联动上传抓拍通道",
  tx1902132: "玩手机联动上传主码流视频通道",
  tx1902133: "玩手机联动上传子码流视频通道",
  tx1902134: "玩手机联动上传抓拍通道",
  tx1902135: "未系安全带联动上传主码流视频通道",
  tx1902136: "未系安全带联动上传子码流视频通道",
  tx1902137: "未系安全带联动上传抓拍通道",

  /** 盲区参数 */
  tx1903001: "后方接近报警时间阈值",
  tx1903002: "侧后方接近报警时间阈值",

  /** 车辆监测系统参数 - 湘标 */
  tx1904101: "超员报警前后视频录制时间",
  tx1904102: "超员报警照片张数",
  tx1904103: "超员报警拍照片间隔时间",
  tx1904104: "超员报警联动上传主码流视频通道",
  tx1904105: "超员报警联动上传子码流视频通道",
  tx1904106: "超员报警联动上传抓拍通道",

  /** 批量设置驾驶员抓拍参数 - 湘标 */
  tx1905101: "离线人脸比对开关",
  tx1905102: "人脸比对(DSM人脸图片)成功阈值",
  tx1905103: "人脸比对(手机人脸图片)成功阈值",
  tx1905104: "语音播报比对结果使能",

  /** 批量设置驾驶员抓拍参数 - 湘标 */
  tx1906101: "ACC抓拍使能",
  tx1906102: "ACC抓拍起始速度",
  tx1906103: "ACC抓拍速度持续时间",
  tx1906104: "刷卡抓拍使能",
  tx1906105: "刷卡抓拍起始速度",
  tx1906106: "刷卡抓拍速度持续时间",
  tx1906107: "定时抓拍使能",
  tx1906108: "定时抓拍起始速度",
  tx1906109: "定时抓拍速度持续时间",
  tx1906110: "定时拍照间隔",
  tx1906111: "离开返回抓拍使能",
  tx1906112: "离开返回抓拍起始速度",
  tx1906113: "离开返回抓拍持续时间",
  tx1906114: "人脸图片地址协议",
  tx1906115: "驾驶员图片",
  tx1906116: "驾驶员图片来源",

  tx1908001: "URL地址",
  tx1908002: "拨号点名称",
  tx1908003: "拨号用户名",
  tx1908004: "拨号密码",
  tx1908005: "服务器地址",
  tx1908006: "TCP端口",
  tx1908007: "UDP端口",
  tx1908008: "制造商ID",
  tx1908009: "硬件版本",
  tx1908010: "固件版本",
  tx1908011: "连接到指定服务器时限(分)",

  tx1909000: "轮询时长",
  tx1909001: "正反转轮询设置",
  tx1909002: "载重轮询设置",
  tx1909003: "温度传感器轮询设置",
  tx1909004: "液位传感器轮询设置",
  tx1909005: "油耗传感器轮询设置",
  tx1909006: "湿度传感器轮询设置",
  tx1909007: "里程传感器轮询设置",
  tx1909008: "外接IO控制器轮询设置",
  tx1909009: "胎压传感器轮询设置",
  tx1909010: "原车OBD轮询设置",
  tx1909011: "工时轮询设置",
  tx1909012: "加速度检测器轮询设置",
  tx1909013: "SIM卡功能轮询设置",
  tx1909014: "压力传感器轮询设置",
  tx1909015: "基站定位轮询设置",
  tx1909016: "振动传感器轮询设置",
  tx1909017: "发动机转速轮询设置",
  tx1909018: "行业信息终端机轮询设置",
  tx1909019: "RFID轮询设置",
  tx1909020: "运输证IC卡读卡器轮询设置",
  tx1909021: "IO输入检测轮询设置",
  tx1909022: "WIFI定位轮询设置",
  tx1909023: "车载导航显示屏轮询设置",

  tx1910001: "断开油电",
  tx1910002: "开启油电",

  /** 终端参数 */
  tx1911001: "终端心跳间隔",
  tx1911002: "TCP消息应答超时",
  tx1911003: "TCP消息重传次数",
  tx1911004: "UDP消息应答超时",
  tx1911005: "UDP消息重传次数",
  tx1911006: "SMS消息应答超时",
  tx1911007: "SMS消息重传次数",
  tx1911008: "服务器TCP端口",
  tx1911009: "服务器UDP端口",

  tx1911010: "监控平台电话号码",
  tx1911011: "监控平台SMS号码",
  tx1911012: "监听电话号码",
  tx1911013: "监听平台特权SMS号码",
  tx1911014: "复位电话号码",
  tx1911015: "恢复出厂设置号码",
  tx1911016: "接收终端SMS报警号码",
  tx1911017: "终端电话监听策略",
  tx1911018: "每次最长通话时长",
  tx1911019: "当月最长通话时长",

  tx1911020: "汇报策略",
  tx1911021: "汇报方案",
  tx1911022: "驾驶员未登时间间隔",
  tx1911023: "驾驶员未登录汇报距离间隔",
  tx1911024: "休眠汇报时间间隔",
  tx1911025: "休眠时汇报距离间隔",
  tx1911026: "紧急报警时间间隔",
  tx1911027: "紧急报警时汇报距离间隔",
  tx1911028: "缺省汇报时间间隔",
  tx1911029: "缺省距离汇报间隔",
  tx1911030: "拐点补偿角度",
  tx1911031: "非法位移阈值",

  tx1911032: "超速报警速度",
  tx1911033: "超速持续时长",
  tx1911034: "连续驾驶时间门限",
  tx1911035: "当天驾驶时间门限",
  tx1911036: "最小休息时间",
  tx1911037: "最长停车时间",

  tx1911038: "图像/视频质量",
  tx1911039: "饱和度",
  tx1911040: "亮度",
  tx1911041: "色度",
  tx1911042: "对比度",
  tx1911043: "定时拍照设置",
  tx1911044: "定距拍照设置",

  tx1911045: "车辆里程表读数",
  tx1911046: "车辆所在省市区域",
  // tx1911047: '车辆所在市域',

  tx1911048: "CAN总线通道1采集时间间隔",
  tx1911049: "CAN总线通道1上传时间间隔",
  tx1911050: "CAN总线通道2采集时间间隔",
  tx1911051: "CAN总线通道2上传时间间隔",
  tx1911052: "CAN总线ID单独采集设置",

  tx1911053: "GNS定位模式",
  tx1911054: "GNSS波特率",
  tx1911055: "GNSS模块详细定位数据输出频率",
  tx1911056: "GNSS模块详细定位数据采集频率",
  tx1911057: "GNSS模块详细定位数据上传方式",
  tx1911058: "GNSS模块详细定位数据上传设置",

  tx1911059: "音视频参数设置",
  tx1911060: "音视频通道列表设置",
  tx1911061: "单独视频通道参数设置",
  tx1911062: "特殊报警录像参数设置",
  tx1911063: "视频相关报警屏蔽",
  tx1911064: "图像分析报警参数设置",
  tx1911065: "终端休眠唤醒模式设置",

  tx1911066: "休眠唤醒模式",
  tx1911067: "唤醒条件类型",
  tx1911068: "定时唤醒日期",
  tx1911069: "定时唤醒时间段",
  tx1911070: "时间段一",
  tx1911071: "时间段二",
  tx1911072: "时间段三",
  tx1911073: "时间段四",

  tx1912001: "控制指令",
  tx1912002: "关闭音视频类型",
  tx1912003: "申请切换的码流",

  tx1913001: "轮胎规格型号",
  tx1913002: "胎压单位",
  tx1913003: "正常胎压值",
  tx1913004: "胎压不平衡门限",
  tx1913005: "慢漏气门限",
  tx1913006: "低压阈值",
  tx1913007: "高压阈值",
  tx1913008: "高温阈值",
  tx1913009: "电压阈值",
  tx1913010: "定时上报时间阈值",

  // form表单extra提示
  /** 苏标 */
  tx2001000: "范围0~60000(米)0表示不抓拍",
  tx2001001: "范围0-60(秒)0表示不录像",
  tx2001002: "范围0-10(0表示不抓拍)",
  tx2001003: "范围0~8,0表示静音",
  tx2001004: "范围0~220(km/h)触发报警时 车速高于阈值为二级报警,否则为一级",
  tx2001005:
    "范围0~60(km/h) 仅适用道路偏移、前向碰撞,车距过近和频繁变道。车速高于阈值才能报警",
  tx2001006: "范围1~10",
  tx2001007: "范围0~3600(秒)",
  tx2001008: "范围3~10",
  tx2001009: "范围30~120",
  tx2001010: "单位100ms 范围1~10",
  tx2001011: "单位100ms 取值范围10-50",
  tx2001012: "单位100ms 范围10~50 默认使用国际标规定值27",
  tx2001013: "范围0~10",
  tx2001014: "范围1~5",
  tx2001015: "单位100ms 范围10~50 默认值30",
  tx2001016: "单位km/h，取值范围 0~220，低于该值时进行报警，高于该值时功能关闭",
  tx2001017: "单位100ms 范围10~50 默认值10",
  tx2001020: "195/65R16 91V 12个字符 默认值 900R20",
  tx2001021: "范围0-100(单位%,达到冷态气压值)",
  tx2001022: "单位摄氏度",
  tx2001023: "范围0-100(%)",

  /** 湘标 */
  tx2002000:
    "取值范围 0~60，仅用适用于车道偏离、前方车辆碰撞预警。表示当车速高于此阈值才使能报警功能",
  tx2002001: "范围0~60(km/h) 表示当车速高于此阈值才使能报警功能",
  tx2002002: "范围60~60000(秒)",
  tx2002003: "单位100ms 范围1~5",
  tx2002004: "单位% 范围1~100",
  tx2002005: "单位 100ms，取值范围 10~50，默认国标规定值27",
  tx2002006:
    "单位 km/h，取值范围 0~60，默认值 30 表示当车速高于此阈值才使能报警功能",

  tx2009000: "当选项框为0表示不下发",
  tx2009001: "外设消息长度",

  // 用户
  tx210000: "用户",
  tx210001: "用户名称",
  // tx210002: '角色',
  tx210003: "创建人",
  tx210004: "登录账号",
  tx210005: "登录密码",
  tx210006: "确认密码",
  tx210007: "旧密码",
  tx210008: "新密码",
  tx210009: "确认密码和新密码不一致",

  // 角色
  tx220000: "角色",
  tx220001: "角色名称",
  tx220003: "角色信息",

  // 菜单
  tx230000: "权限",
  tx230001: "权限描述",
  tx230002: "权限信息",
  tx230003: "上级菜单",
  tx230004: "权限类型",
  tx230005: "层级",
  tx230006: "路由地址",
  tx230007: "功能类型",
  tx230008: "菜单顺序",
  tx230009: "图标",
  tx230010: "是否启用",
  tx230011: "权限key",

  // 传感器
  tx240001: "传感器类型",
  tx240002: "键",
  tx240003: "值",
  tx240004: "上报时间",
  tx240005: "旋转状态",
  tx240006: "旋转方向",
  tx240007: "旋转速度（转/分钟）",
  tx240008: "旋转持续时间（分钟）",
  tx240009: "累计脉冲数量",
  tx240010: "载重状态",
  tx240011: "累计运行时间（小时）",
  tx240012: "装载次数",
  tx240013: "载荷重量（kg）",
  tx240014: "装载/卸载重量（kg）",
  tx240015: "AD值",
  tx240016: "浮动零点",
  tx240017: "载重相对值",
  tx240018: "重量单位",
  tx240019: "重要数据标识",

  // 地图操作
  tx300001: "地图操作",
  tx300002: "拖动地图",
  tx300003: "矩形查车",
  tx300004: "默认位置",
  tx300005: "矩形放大",
  tx300006: "矩形缩小",
  tx300007: "测距",
  tx300008: "地图切换",

  // 电子围栏
  tx310000: "矩形电子围栏",
  tx310001: "多边形电子围栏",
  tx310002: "圆形电子围栏",
  tx310003: "关键点电子围栏",
  tx310004: "线路电子围栏",
  tx310005: "区域名称",
  tx310006: "线路名称",
  tx310007: "线段名称",
  tx310008: "进入区域",
  tx310009: "离开区域",
  tx310010: "时段设置",
  tx310011: "限速设置",
  tx310012: "限速(km/h)",
  tx310013: "限速",
  tx310014: "延迟报警",
  tx310015: "坐标",
  tx310016: "原坐标",
  tx310017: "报警给司机",
  tx310019: "报警给平台",
  tx310021: "根据时间",
  tx310022: "限速延时报警(秒)",
  tx310023: "关键点名称",
  tx310024: "区域半径",
  tx310025: "规定时间",
  tx310026: "必须到达",
  tx310027: "必须离开",
  tx310028: "开门",
  tx310029: "围栏名称",
  tx310030: "围栏类型",
  tx310031: "规则类型",
  tx310032: "绑车",
  tx310033: "半径",
  tx310034: "开启通信模块",
  tx310035: "关闭通信模块",
  tx310036: "采集",
  tx310037: "不采集",
  tx310038: "允许开门",
  tx310039: "禁止开门",
  tx310040: "线路宽度",
  tx310041: "偏离延迟报警",
  tx310042: "路段设置",
  tx310043: "行驶时间",
  tx310044: "行驶过长阈值",
  tx310045: "行驶不足阈值",
  tx310046: "拐点经度",
  tx310047: "拐点纬度",
  tx310048: "下发类型",
  tx310049: "下发结果",
  tx310050: "下发时间",
  tx310051: "下发平台",
  tx310052: "下发终端",
  tx310053: "通信模块",
  tx310054: "采集GNSS",

  //用户设置
  tx320000: "设置",
  tx320001: "视频设置",
  tx320002: "启用实时报警信息弹窗提示",
  tx320003: "启用报警视频弹窗提示",
  tx320004: "启用危险车辆提醒信息弹窗提示",
  tx320005: "启用上级查岗信息弹窗提示",
  tx320006: "启用报警声音提示",
  tx320007: "默认视频码流",
  tx320008: "默认请求音视频",
  tx320009: "自动关闭视频",
  tx320010: "自动关闭监听",
  tx320011: "自动关闭时间",
  tx320012: "地图设置",
  tx320013: "默认地图",
  tx320014: "地图车辆标签",
  tx320015: "实时状态显示类型",
  tx320016: "地图初始层级",
  tx320017: "默认地图中心经度",
  tx320018: "默认地图中心纬度",
  tx320019: "默认地图车标类型",
  tx320020: "视频默认通道数",
  tx320021: "车辆默认到期时间（月）",
  tx320022: "请输入正整数",

  //日志管理
  tx330000: "日志编号",
  tx330002: "操作结果",
  tx330003: "操作人员",
  tx330004: "操作时间",
  tx330005: "操作内容",
  tx330007: "近一个月",
  tx330008: "近三个月",
  tx330009: "近半年",
  tx330010: "命令类型",
  tx330011: "命令内容",
  tx330012: "流水号",
  tx330013: "执行结果",

  //规则中心-报警设置
  tx340001: "声音",
  tx340002: "弹窗",
  tx340003: "弹窗文本颜色",
  tx340004: "时长统计",
  tx340005: "自动下发文本",
  tx340006: "上传附件",
  tx340007: "新增报警设置",
  tx340008: "报警ID/编码",
  tx340009: "实时视频联动",
  tx340010: "拍照联动",
  tx340011: "启用配置",
  tx340012: "启用报警",
  tx340013: "个性化配置",
  tx340014: "修改报警设置",
  tx340015: "报警设置详情",
  tx340016: "批量保存",
  tx340017: "无该企业权限",

  // 指数权重设置
  tx350001: "ID",
  tx350002: "报警来源",
  tx350003: "报警类型",
  tx350004: "报警次数",
  tx350005: "次数权重",
  tx350006: "报警速度",
  tx350007: "速度权重",
  tx350008: "指数类型",
  tx350009: "指数权重",
  tx350010: "权重指数详情",
  tx350011: "最大扣分值",
  tx350012: "日评分",
  tx350013: "日评分详情",
  tx350014: "报警评分",
  tx350015: "评分规则",
  tx350025: "报警最小次数",
  tx350026: "报警最大次数",
  tx350027: "每次扣分值",

  // 实时评分设置
  tx350016: "速度权重设置",
  tx350017: "危险等级设置",
  tx350018: "危险等级名称",
  tx350019: "最小分值",
  tx350020: "最大分值",
  tx350021: "报警最小速度",
  tx350022: "报警最大速度",
  tx350023: "速度权重",
  tx350024: "评分规则",

  // 地图类型
  tx360001: "高德",
  tx360002: "百度",
  tx360003: "谷歌",

  // 右键指令
  tx370001: "位置上报时间间隔(秒)",
  tx370002: "跟踪有效期(秒)",
  tx370003: "开始监听",
  tx370004: "停止监听",
  tx370005: "未识别当前驾驶员",

  // 平台参数
  tx380005: "默认地图车标类型",
  tx380006: "地图车辆标签",
  tx380007: "实时状态显示类型",
  tx380008: "实时音视频状态通知",
  tx380009: "地图中心经度",
  tx380010: "地图中心纬度",
  tx380011: "地图等级",
  tx380012: "数据刷新间隔",
  tx380013: "百度地图key",
  tx380014: "百度地图secret",
  tx380015: "高德地图key",
  tx380016: "高德地图secret",
  tx380017: "web界面无操作时间",
  tx380018: "视频连接无数据传输时间",
  tx380019: "视频直播码流",
  tx380020: "视频直播资源类型",
  tx380021: "视频直播自动关闭时长",
  tx380022: "音频对接时长",
  tx380023: "视频默认通道数",
  tx380024: "默认地图LEVEL",
  tx380025: "平台名称",
  tx380026: "默认地图",
  tx380027: "数据显示倍数",
  tx380028: "平台",

  // 车标设置
  tx390001: "默认标志",
  tx390002: "修改时间",
  tx390003: "车标",
  tx390004: "车标详情",
  tx390005: "车标类型",
  tx390006: "车标地址",

  // 上级查岗
  tx400001: "查岗对象",
  tx400002: "查岗类型",
  tx400003: "查岗问题",
  tx400004: "回复",

  // 批量指令下发-其他指令-行车记录仪
  tx500001: "脉冲系数",
  tx500002: "D0",
  tx500003: "车辆VIN号",
  tx500004: "D1",
  tx500005: "实现时间",
  tx500006: "D2",
  tx500007: "脉冲系数",
  tx500008: "D3",
  tx500009: "车辆分类",
  tx500010: "D4",
  tx500011: "D5",
  tx500012: "D6",
  tx500013: "D7",

  // 电话本设置
  tx600001: "电话本设置",
  tx600002: "标志",
  tx600003: "电话号码",
  tx600004: "联系人",
  // 信息菜单设置
  tx601001: "信息菜单设置",
  tx601002: "菜单ID",
  tx601003: "菜单内容",
  // 事件报告设置
  tx602001: "事件报告设置",
  tx602002: "事件ID",
  tx602003: "事件内容",
  // 提问下发
  tx603001: "提问下发",
  tx603002: "发送类型",
  tx603003: "候选答案1",
  tx603004: "问题内容",
  tx603005: "候选答案2",
  tx603006: "候选答案3",
  tx603007: "答案ID",
  tx603008: "答案",
  tx603009: "结果",

  // 清理缓存
  tx604001: "清理成功",
  tx604002: "库索引",
  tx604003: "redis键",
  tx604004: "Hash项",
  tx604005: "类型",
  tx604006: "请选择库索引",
  tx604007: "请输入redis键",
  tx604008: "请输入Hash项",
  tx604009: "请选择类型",

  // 驾驶时长
  tx605001: "提交成功",
  tx605002: "试驾时长下限",
  tx605003: "请输入试驾时长下限",
  tx605004: "试驾时长上限",
  tx605005: "请输入试驾时长上限",

  // 跟踪
  tx606001: "开始跟踪",
  tx606002: "停止跟踪",
  tx606003: "定位间隔",
  tx606004: "跟踪时长",
  //拍照
  tx606005: "指令类型",
  tx606010: "拍照间隔",
  tx606012: "品质",
  tx606013: "保存方式",
  tx606016: "录像时长",

  //设备拍照查询
  tx607001: "设备拍照查询",

  //报警地图查询
  tx608001: "报警地图查询",
  tx608002: "车组",
  tx608003: "车速",
  tx608004: "报警明细",
  //车辆上下线记录查询
  tx609001: "车辆上下线记录查询",
  tx609002: "地点",
  tx609003: "记录类型",
  tx609004: "最后上线时间",
  tx609005: "ICCID",


  //车辆在线状态查询
  tx6010001: "车辆在线状态查询",
  tx6010002: "终端卡号",
  tx6010003: "上线状态",
  tx6010004: "离线时长（小时）",


  //语音对讲
  tx6011001: "开始对讲",
  tx6011002: "请求中",
  tx6011003: "结束对讲",
};
