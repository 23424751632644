import React, {
  useCallback,
  forwardRef,
  ForwardRefRenderFunction,
  memo,
  useEffect,
} from "react";
import { Spin, TreeSelect as AntdTreeSelect } from "antd";
import {
  useUpdateProps,
  useHandleHighLightSearch,
  useHandleAxios,
  useDefaultValue,
} from "./hook";
import { TreeSelectProps, TreeSelectRef, StateType } from "./interface";
import { handleTreeData } from "util/comm";
import { Tools } from "./hook/interface";
import { useSetState } from "react-use";
import { isUndefined } from "lodash-es";
import "./index.less";

const TreeSelect: ForwardRefRenderFunction<TreeSelectRef, TreeSelectProps> = (
  _props,
  ref
) => {
  /** 统一处理处理默认值 */
  const props = useDefaultValue(_props);

  const {
    defaultAllAfterQuery = false,
    treeData: propsTreeData,
    loading: propsLoading,
    searchValue: propsSearchValue,
    query,
    payload,
    handleResult,
    queryTime,
    onSearch,
    onFocus,
    ...extra
  } = props;

  const [state, setState] = useSetState<StateType>({
    treeData: propsTreeData,
    searchTreeData: undefined,
    searchValue: propsSearchValue,
    loading: false,
  });

  const tools: Tools = { props, state, setState };

  const { treeData, searchTreeData, searchValue, loading } = state;

  const finallyLoading = propsLoading || loading;
  const finallyTreeData = searchTreeData || treeData;

  /** 封装组件内部的请求，并抛出请求接口 */
  const queryData = useHandleAxios(tools);

  /** 封装组件的高亮搜索功能 */
  const treeNodeFilterProp = useHandleHighLightSearch(tools);

  //遍历树
  async function foreachTree(
    data: any[],
    callback: Function,
    fatherData?: any[] | null,
    childrenName = "children"
  ) {
    for (let i = 0; i < data.length; i++) {
      await callback(data[i], i, fatherData);
      if (data[i][childrenName] && data[i][childrenName].length > 0) {
        await foreachTree(
          data[i][childrenName],
          callback,
          data[i],
          childrenName
        );
      }
    }
  }

  async function getValueList(finallyTreeData: any[]) {
    let seArr: string[] = [];
    await foreachTree(finallyTreeData, (item: any) => {
      if (!Reflect.has(item, "selectable")) {
        seArr.push(item.value);
      }
    });
    return seArr;
  }
  /** 处理请求后的默认回显 */
  useEffect(() => {
    const setCheckData = async () => {
      try {
        if (
          defaultAllAfterQuery &&
          finallyTreeData &&
          finallyTreeData?.length
        ) {
          let cheList = await getValueList(finallyTreeData);
          // @ts-ignore
          props.onChange && props.onChange(cheList ?? []);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    setCheckData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultAllAfterQuery, finallyTreeData]);

  const handleSearch = useCallback<NonNullable<TreeSelectProps["onSearch"]>>(
    (value) => {
      onSearch && onSearch(value);
      isUndefined(propsSearchValue) && setState({ searchValue: value });
    },
    [onSearch, propsSearchValue, setState]
  );

  const handleFocus = useCallback<NonNullable<TreeSelectProps["onFocus"]>>(
    (e) => {
      onFocus && onFocus(e);
      isUndefined(treeData) && queryTime === "onFocus" && queryData();
    },
    [onFocus, queryData, queryTime, treeData]
  );

  const dropdownRender = useCallback<
    NonNullable<TreeSelectProps["dropdownRender"]>
  >(
    (menu) => <Spin spinning={finallyLoading || false}>{menu}</Spin>,
    [finallyLoading]
  );

  /** 统一处理：当props改变时更新state的值 */
  useUpdateProps(tools);

  return (
    <AntdTreeSelect
      ref={ref}
      {...extra}
      treeData={finallyTreeData}
      loading={finallyLoading}
      searchValue={searchValue}
      onSearch={handleSearch}
      onFocus={handleFocus}
      treeNodeFilterProp={treeNodeFilterProp}
      dropdownRender={dropdownRender}
    />
  );
};

export default memo(forwardRef(TreeSelect));
