import React, { FC, useState } from "react";
import { positionStory, intlStore } from "store";
import { observer } from "mobx-react";

var x_PI = 3.14159265358979324 * 3000.0 / 180.0;
var PI = 3.1415926535897932384626;
var a = 6378245.0;
var ee = 0.00669342162296594323;

/**
* 经度转换
* @param { Number } lng
* @param { Number } lat
*/
function transformlat(lng: any, lat: any) {
  var ret = -100.0 + 2.0 * lng + 3.0 * lat + 0.2 * lat * lat + 0.1 * lng * lat + 0.2 * Math.sqrt(Math.abs(lng))
  ret += (20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0 / 3.0
  ret += (20.0 * Math.sin(lat * PI) + 40.0 * Math.sin(lat / 3.0 * PI)) * 2.0 / 3.0
  ret += (160.0 * Math.sin(lat / 12.0 * PI) + 320 * Math.sin(lat * PI / 30.0)) * 2.0 / 3.0
  return ret
}

/**
* 纬度转换
* @param { Number } lng
* @param { Number } lat
*/
function transformlng(lng: any, lat: any) {
  var ret = 300.0 + lng + 2.0 * lat + 0.1 * lng * lng + 0.1 * lng * lat + 0.1 * Math.sqrt(Math.abs(lng))
  ret += (20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0 / 3.0
  ret += (20.0 * Math.sin(lng * PI) + 40.0 * Math.sin(lng / 3.0 * PI)) * 2.0 / 3.0
  ret += (150.0 * Math.sin(lng / 12.0 * PI) + 300.0 * Math.sin(lng / 30.0 * PI)) * 2.0 / 3.0
  return ret
}

/**
* 判断是否在国内，不在国内则不做偏移
* @param {*} lng
* @param {*} lat
*/
function outOfChina(lng: any, lat: any) {
  return (lng < 72.004 || lng > 137.8347) || ((lat < 0.8293 || lat > 55.8271) || false)
}

/**
 * WGS84转GCj02
 * @param lng
 * @param lat
 * @returns {*[]}
 */
function wgs84togcj02(lng: any, lat: any) {
  if (outOfChina(lng, lat)) {
    return [lng, lat]
  } else {
    var dlat = transformlat(lng - 105.0, lat - 35.0)
    var dlng = transformlng(lng - 105.0, lat - 35.0)
    var radlat = lat / 180.0 * PI
    var magic = Math.sin(radlat)
    magic = 1 - ee * magic * magic
    var sqrtmagic = Math.sqrt(magic)
    dlat = (dlat * 180.0) / ((a * (1 - ee)) / (magic * sqrtmagic) * PI)
    dlng = (dlng * 180.0) / (a / sqrtmagic * Math.cos(radlat) * PI)
    const mglat = lat + dlat
    const mglng = lng + dlng
    return [mglng, mglat]
  }
}

/**
 * 火星坐标系 (GCJ-02) 与百度坐标系 (BD-09) 的转换
 * 即谷歌、高德 转 百度
 * @param lng
 * @param lat
 * @returns {*[]}
 */
function gcj02tobd09(lng: any, lat: any) {
  var z = Math.sqrt(lng * lng + lat * lat) + 0.00002 * Math.sin(lat * x_PI)
  var theta = Math.atan2(lat, lng) + 0.000003 * Math.cos(lng * x_PI)
  var bd_lng = z * Math.cos(theta) + 0.0065
  var bd_lat = z * Math.sin(theta) + 0.006
  return [bd_lng, bd_lat]
}

function wgs84tobd09(lng: any, lat: any) {
  // wgs84先转为火星坐标系
  const gcj02 = wgs84togcj02(lng, lat)
  // 火星坐标系转百度坐标系
  const result = gcj02tobd09(gcj02[0], gcj02[1])
  return result
}

interface LnglatProps {
  lnglat: any
  type?: string
  style?: React.CSSProperties
}

const Lnglats: FC<LnglatProps> = ({ lnglat, style }) => {
  const [address, setAddress] = useState<string>()
  if (positionStory.mapType == 'amap') {//高德
    const gdarr = wgs84togcj02(lnglat[0], lnglat[1])
    const regeoAmapCode = (lnglats: any) => {
      if (lnglats && lnglats[0] && lnglats[1]) {
        if (intlStore.lang === "en_US") {
          //英文版本调用的是 谷歌 api 反编码
          // @ts-ignore
          const geocodedr = new google.maps.Geocoder();
          geocodedr.geocode({ location: {
            lat: parseFloat(lnglats[1]),
            lng: parseFloat( lnglats[0]),
          } ,
          language:'en'
        })
          .then((response:any) => {
            if(response?.results&&response?.results?.[0]){
              setAddress(response.results[0].formatted_address)
            }
          })
          .catch((e:any) => console.log("Geocoder failed due to: " + e));

        } else {
          // @ts-ignore
          let geocoder = new window.AMap.Geocoder({
            radius: 1000, //以已知坐标为中心点，radius为半径，返回范围内兴趣点和道路信息
            extensions: "all",//返回地址描述以及附近兴趣点和道路信息，默认"base"
          });
          // @ts-ignore
          geocoder.getAddress(lnglats, function (status: string, result: any) {
            if (status === 'complete' && result.info === 'OK') {
              const poisarr = result.regeocode?.pois
              const formattedAddress = result.regeocode?.formattedAddress
              const formattedAddressInfo = `${formattedAddress}${poisarr[0]?.direction || ''}${poisarr[0]?.distance || '0'}米`
              const address = poisarr && poisarr.length > 0 ? formattedAddressInfo : formattedAddress;
              setAddress(address)
            } else {
              console.log(result)
              // message.error('根据地址解析失败')
            }
          });
        }


      }
    }

    regeoAmapCode(gdarr)
  }

  if (positionStory.mapType == 'baidu') {//百度
    //@ts-ignore
    let myGeo = new window.BMap.Geocoder({
      radius: 1000,
      extensions_poi: "1"
    });
    const zbarr = wgs84tobd09(lnglat[0], lnglat[1])
    //@ts-ignore   
    myGeo.getLocation(new window.BMap.Point(zbarr[0], zbarr[1]), function (result: any) {
      if (result) {
        const poisarr = result.surroundingPois
        const formattedAddress = result.address
        const formattedAddressInfo = `${poisarr[0]?.address || formattedAddress} ${poisarr[0]?.title || ''}`
        const address = poisarr && poisarr.length > 0 ? formattedAddressInfo : formattedAddress;
        setAddress(address)
      }
    });
  }

  return <span style={style ? style : {}}>{lnglat[0] != '0.0' && lnglat[1] != '0.0' ? address : '-'}</span>
}

export default observer(Lnglats)
